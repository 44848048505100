import React, { Fragment } from 'react';
import RegisterFrom from './RegisterFrom';

const RegisterBgImg = () => {
  return (
    <RegisterFrom logoClassMain='text-start' />
  );
};

export default RegisterBgImg;
