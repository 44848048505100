import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import _ from 'lodash';
import WalletWrapper from './WalletWraper';
import TermCard from './TermCard';
import UserContext from '../../../_helper/User';
import CreateFixModal from './CreateFixModal';

const Home = () => {

  const { userAvailableTermInfo, updateUserWalletInfo, updateUserAvailableTerms } = useContext(UserContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    updateUserWalletInfo();
    updateUserAvailableTerms();
  }, [updateUserWalletInfo, userAvailableTermInfo]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Dashboard' title='Home' />
      <Container fluid={true}>
        <Row className='widget-grid'>
          <WalletWrapper />
          <div className='d-flex w-75'>
            <Button className='mb-3 font18-bold font-outfit p-0' color='warning' onClick={toggle} style={{ width: '200px', height: '40px' }}>{'+ Start New Term'}</Button>
          </div>
          <Row>
            {userAvailableTermInfo.map((item, i) => (
              <Col xs={12} md={4} xxl={4} xl={6} key={i}>
                <TermCard data={item} />
              </Col>
            ))}
          </Row>
        </Row>
        <CreateFixModal modal={modal} toggle={toggle} />
      </Container>
    </Fragment>
  );
};

export default Home;
