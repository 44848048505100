import React, { useEffect, useState } from 'react';
import Context from './index';

const GalleryProvider = (props) => {
    const [images, setImage] = useState([]);
    const [smallImages, setSmallImages] = useState([]);
    const [masonryImg, setMasonryImg] = useState([]);

    return (
        <Context.Provider
            value={{
                ...props,
                images,
                smallImages,
                masonryImg
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default GalleryProvider;