import React, { Fragment, useContext, useState } from "react";
import { Btn, H4, P, Image } from "../../../../AbstractElements";
import { Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import { loginAPI, sendVerificationRequestAPI } from "../../../../Library/apis";
import { toast } from "react-toastify";
import UserContext from "../../../../_helper/User";
import { IMAGES } from "../../../../Library/theme";
import MothriftLogo from "../../Common/MothriftLogo";

const LoginMain = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    userInfo: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const navigate = useNavigate();

  const { setUserInfo, socket } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await loginAPI({ ...formData, socketId: socket.id });

      if (data?.body?.token) {
        data?.body?.userInfo && setUserInfo(data?.body?.userInfo);
        localStorage.setItem("authenticated", true);
        localStorage.setItem("login", true);
        localStorage.setItem("userInfo", JSON.stringify(data?.body?.userInfo));
        localStorage.setItem("token", data?.body?.token);

        navigate(`/mothrift/home`);
      } else {
        toast.success("One-time passcode sent.", { autoClose: 5000 });
        navigate("/mothrift/auth/code-verification", {
          state: { formData: formData, type: "login" },
        });
      }
    } catch (error) {
      console.log(JSON.stringify(error?.response?.status));
      if (error?.response?.status == 402) {
        sendVerificationRequestAPI({ userInfo: formData.userInfo })
          .then(() => {
            toast.success("Verification code sent again.", { autoClose: 5000 });
            navigate("/mothrift/auth/code-verification", {
              state: { formData: formData },
            });
          })
          .catch((e) => console.log(e));
      }
      toast.error(error?.response?.data?.message, { autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MothriftLogo />
      <div className="login-card">
        <div>
          <div className="login-main">
            <Form className="theme-form login-form" onSubmit={handleSubmit}>
              <H4 attrH4={{ className: "text-center text-warning" }}>
                Login to your account
              </H4>
              <FormGroup>
                <Label className="col-form-label m-0 text-black">
                  Email or Phone Number
                </Label>
                <Input
                  className="form-control"
                  type="text"
                  name="userInfo"
                  required
                  placeholder="Enter Email or Phone Number"
                  value={formData.userInfo}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="position-relative">
                <Label className="col-form-label m-0 text-black">
                  Password
                </Label>
                <div className="position-relative">
                  <Input
                    className="form-control"
                    type={togglePassword ? "text" : "password"}
                    name="password"
                    required
                    placeholder="*********"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <div
                    className="show-hide"
                    onClick={() => setTogglePassword(!togglePassword)}
                  >
                    <span
                      className={`text-warning ${togglePassword ? "" : "show"}`}
                    ></span>
                  </div>
                </div>
              </FormGroup>

              <FormGroup>
                <Btn
                  attrBtn={{
                    className: "d-block w-100 mt-2",
                    color: "warning",
                    type: "submit",
                  }}
                >
                  {loading ? (
                    <>
                      <Spinner size="sm" color="light" />
                      {" Loading..."}
                    </>
                  ) : (
                    "LOG IN"
                  )}
                </Btn>
              </FormGroup>

              <P attrPara={{ className: "text-center mt-4 mb-4" }}>
                <Link
                  className="ms-2 text-dark f-w-600"
                  to={`/mothrift/auth/forget-pwd`}
                >
                  Forgot password?
                </Link>
              </P>

              <P attrPara={{ className: "text-center mb-0" }}>
                Don't have account?
                <Link
                  className="ms-2 text-warning"
                  to={`/mothrift/auth/sign-up`}
                >
                  Sign up! It's FREE
                </Link>
              </P>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginMain;
