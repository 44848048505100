import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import ReactInputVerificationCode from 'react-input-verification-code';

import { Btn, H4, H6, P, Image } from '../../../../AbstractElements';
import { confirmOTPCodeAPI, confirmResetPasswordAPI, sendVerificationRequestAPI } from '../../../../Library/apis';
import MothriftLogo from '../../Common/MothriftLogo';

const ForgetPwdMain = () => {
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleCode, setToggleCode] = useState(false);
    const [checkOPT, setCheckOPT] = useState(false);
    const [userInfo, setUserInfo] = useState('');
    const [code, setCode] = useState('');
    const [token, setToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const navigate = useNavigate();

    const handleSend = () => {
        sendVerificationRequestAPI({ userInfo: userInfo }).then(() => {
            toast.success('Sent verification code', { autoClose: 5000 });
            setToggleCode(true);
        }).catch(e => {
            console.log(e)
            const message = e?.response?.data?.mesage || 'Sending verification code error';
            toast.error(message, { autoClose: 5000 });
        });
    };

    const handleConfirmOPTCode = () => {
        confirmOTPCodeAPI({ userInfo, activationCode: code }).then(res => {
            toast.success('Successfully confirmed.', { autoClose: 5000 });
            setToken(res.token);
            setCheckOPT(true);
        }).catch(error => {
            toast.error(error?.response?.data?.message || 'Please input correct verification code.', { autoClose: 5000 });
        })
    }

    const handleChangePassword = () => {
        confirmResetPasswordAPI({ password: newPassword, token }).then(res => {
            toast.success('Password successfully updated', { autoClose: 5000 });
            navigate('/login');
        }).catch(error => {
            toast.error(error?.response?.data?.message || 'Password Reset error', { autoClose: 5000 });
        })
    }

    return (
        <Fragment>
            <section>
                <Container className='p-0 login-page' fluid={true}>
                    <MothriftLogo />
                    <Row className='m-0'>
                        <Col className='p-0'>
                            <div className='login-card'>
                                <div className='login-main'>
                                    <Form className='theme-form login-form'>
                                        <H4 attrH4={{ className: 'text-warning text-center' }}>{'Reset Your Password'}</H4>

                                        <FormGroup>
                                            <Label className='m-0 col-form-label'>{'Email or Phone Number'}</Label>
                                            <Input
                                                className='form-control'
                                                type='text'
                                                required
                                                placeholder='Input your user information'
                                                value={userInfo}
                                                onChange={(e) => setUserInfo(e.target.value)} />
                                        </FormGroup>

                                        <FormGroup className='text-end'>
                                            <Btn attrBtn={{ className: 'btn-block ', color: 'warning', onClick: handleSend }}>{'Send'}</Btn>
                                        </FormGroup>

                                        {!checkOPT && <>
                                            <FormGroup>
                                                <Label>{'Enter OTP'}</Label>
                                                <div className="passcode-container">
                                                    <ReactInputVerificationCode
                                                        length={6}
                                                        autoFocus
                                                        placeholder=""
                                                        onChange={setCode}
                                                    />
                                                </div>
                                            </FormGroup>

                                            <FormGroup className='text-end'>
                                                <Btn attrBtn={{ className: 'btn-block ', color: 'warning', onClick: handleConfirmOPTCode, disabled: !toggleCode }}>{'Verify'}</Btn>
                                            </FormGroup>
                                        </>}

                                        {checkOPT && <>
                                            <FormGroup className='position-relative'>
                                                <Label className='col-form-label m-0'>New Password</Label>
                                                <div className='position-relative'>
                                                    <Input className='form-control' type={togglePassword ? 'text' : 'password'} required placeholder='New Password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                    <div className='show-hide text-warning' onClick={() => setTogglePassword(!togglePassword)}>
                                                        <span className={togglePassword ? 'text-warning' : 'show text-warning'}></span>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <Btn attrBtn={{ color: 'warning', className: 'btn d-block w-100', onClick: handleChangePassword }}>SAVE</Btn>
                                            </FormGroup>
                                        </>}

                                        <P attrPara={{ className: 'text-center mb-0 mt-3' }}>
                                            Already have an password?
                                            <Link className='ms-2 text-warning' to={`/login`}>
                                                Log In
                                            </Link>
                                        </P>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default ForgetPwdMain;
