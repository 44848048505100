import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';

import { Btn, H1, H2, H3, H5, H6, P } from '../../../../AbstractElements';
import TopHeader from '../TopHeader';
import Footer from '../Footer';

import landing_bg from '../../../../assets/images/landing/landing_top_banner.jpg';

import 'swiper/css';
import '../index.css';
import { HelpCircle } from 'react-feather';
import { Fade } from 'react-reveal';

const FAQPage = () => {

    const sessionData = [
        {
            title: "What is MoThrift?",
            text: "MoThrift is a unique savings platform designed to make saving simple and rewarding. We offer personalized savings plans with fixed returns, providing a secure and hassle-free way to grow your wealth."
        },
        {
            title: "How does MoThrift work?",
            text: "It's easy! Choose a savings plan that fits your goals, fix your money for the specified duration, and watch it grow with guaranteed returns. Our user-friendly interface and transparent processes ensure a seamless savings experience."
        },
        {
            title: "How to send money to your Savings wallet",
            texts: [
                "Sign up at mothrift.com or visit Google Play store to download MoThrift secure app. ",
                "Upon a successful sign up, login to your account to see a 10-digit user ID under your name.",
                "To transfer money to your savings wallet, go to your bank's mobile app or website.",
                `Choose to transfer to another bank. Enter your 10-digit MoThrift user ID and then choose "Teasypay" as financial institution.`,
                "Confirm that your MoThrift account name is correct, then complete the transfer.",
                "If the transfer is successful at your bank, please allow up to 5 minutes for the money to reflect in your MoThrift wallet.",
                "Note that all transfers into your MoThrift account goes into a regular savings wallet. To lock the savings away for a period of time, you need to move it into your Term Savings wallet",
                "Refer others and earn N1,000 after they save money for at least 30 days.",
            ]
        },
        {
            title: "Is my money safe with MoThrift?",
            text: "Absolutely. MoThrift prioritizes the security of your savings. We employ robust security measures to safeguard your funds, providing you with a reliable and trustworthy platform for your financial growth."
        },
        {
            title: "What sets MoThrift apart from other savings platforms?",
            text: "MoThrift stands out with its emphasis on simplicity, fixed returns, and personalized savings plans. We believe in making saving accessible to everyone, regardless of their financial background, while ensuring steady and guaranteed growth."
        },
        {
            title: "Can I withdraw my savings before the specified duration?",
            text: "No. MoThrift plans are designed for a fixed duration to maximize returns."
        },
        {
            title: "Are there any fees associated with using MoThrift?",
            text: "No, MoThrift is committed to transparency. There are no hidden fees. We believe in providing a straightforward savings experience, and what you see is what you get.",
        },
        {
            title: "How do I contact MoThrift customer support?",
            text: `Our customer support team is here to assist you. You can reach us through the \"\Contact Us\"\ section on our platform, where you'll find options for email, live chat, and phone support.`
        },
        {
            title: "Can I have multiple savings plans with MoThrift?",
            text: "Yes, you can have multiple savings plans with MoThrift, each tailored to your specific financial goals. Our platform is designed to accommodate your diverse saving needs."
        },
        {
            title: "Can I change my savings plan after it has started?",
            text: "Once a savings plan has commenced, the terms remain fixed. However, if you have specific needs or circumstances, we encourage you to reach out to our support team, and we'll do our best to assist you."
        },
        {
            title: "Can I delete my account?",
            text: `Yes. Simply login to the MoThrift mobile app using your email address or mobile number and password. On the side bar, go to Account Settings and on Account Settings page, you will see "Delete Account" menu. Press "Delete Account", and then press "Delete". You will be prompted with a question whether you still want to continue to delete the account. Press the "Yes, delete" button to proceed. Note that this action is not reversible.`
        },
    ]

    const [isActivity, setIsActivity] = useState([]);
    const handelChange = (e) => {
        let collapseCopy = [...isActivity];
        collapseCopy[e] = !collapseCopy[e];
        setIsActivity(collapseCopy);
    };

    return (
        <div className="landing-page">
            <div className='first-section' style={{ backgroundImage: `url(${landing_bg})`, height: 100 }}>
                <TopHeader />
            </div>

            <H2 attrH2={{ className: 'm-4 font40-bold font-outfit text-dark' }}>{'Frequently Asked Questions (FAQs) - Mothrift'}</H2>

            <Row className='about-section' style={{ margin: '40px' }}>
                <Col xl='8' lg='6' md='7' className='box-col-8 xl-60'>
                    {sessionData && sessionData.map((item, i) => {
                        return (
                            <Fade right>
                                <Card className='font-outfit' key={i} onClick={() => handelChange(i)} style={{ borderRadius: 8 }}>
                                    <CardHeader className='pb-0 mb-0 pt-0' style={{ borderBottomWidth: 0 }}>
                                        <div className='d-flex' style={{ alignItems: 'center', gap: 15 }}>
                                            <HelpCircle size={28} />
                                            <H3 attrH3={{ className: 'mb-0 font-bold text-grey font-outfit' }}>{item.title}</H3>
                                        </div>
                                    </CardHeader>
                                    <Collapse isOpen={isActivity[i]}>
                                        <CardBody style={{ border: '2px 0 0 0 red solid' }}>
                                            {item.text && <H5 attrH5={{ className: 'font-outfit' }}>{item.text}</H5>}
                                            {item.texts && item.texts.map((text, index) => {
                                                return (
                                                    <div className='d-flex'>
                                                        <H5 attrH5={{ className: 'font-outfit me-2' }}>{`${index + 1}.`}</H5>
                                                        <H5 attrH5={{ className: 'font-outfit' }}>{text}</H5>
                                                    </div>
                                                )
                                            })}
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Fade>
                        );
                    })}
                </Col>
                <H3 attrH3={{ className: 'font-outfit' }}>{'These FAQs aim to provide clarity on common questions users may have about MoThrift, ensuring a transparent and user-friendly experience.'}</H3>
            </Row>

            <Footer />

        </div >
    );
};

export default FAQPage;
