import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { H6, ToolTip } from '../../../AbstractElements';
import CountUp from 'react-countup';
import { COLORS } from '../../../Library/theme';

const WalletWidget = ({ type, balance }) => {

  const data = {
    savings: {
      id: 'savings',
      title: 'Savings Wallet',
      tip: 'All deposits come to this wallet. When you start a new Term Savings, it is deducted from here. All withdrawals are made from this wallet too.',
      color: 'secondary',
      icon: 'cart',
      backColor: COLORS.wallet1,
    },
    terms: {
      id: 'terms',
      title: 'Term Savings Wallet',
      tip: 'This wallet holds the total money you have put away and fixed for a duration.',
      color: 'warning',
      icon: 'return-box',
      backColor: COLORS.wallet2,
    },
    interest: {
      id: 'interest',
      title: 'Interests Wallet',
      tip: 'This wallet holds the total interests that you earn at the end of each Term Savings. You can transfer the amount here to your Savings wallet only.',
      color: 'primary',
      icon: 'tag',
      backColor: COLORS.wallet3,
    }
  }

  const [tooltip, setTooltip] = useState(false);
  const toggle = () => setTooltip(!tooltip);

  return (
    <Card className='widget-1 p-0' style={{ background: data[type].backColor, borderRadius: 15 }}>
      <CardBody className='p-20'>
        <div className='p-2' style={{}} >
          <div className='d-flex' style={{ alignItems: 'center', display: 'flex' }}>
            <H6 attrH6={{ style: { fontSize: '18px', color: COLORS.normal, margin: 0, padding: 0 } }}>{data[type].title}</H6>
            <div className='pt-1'>
              <i className="ms-2 icon-info-alt font-dark" id={data[type].id} style={{ fontSize: '16px' }}></i>
              <ToolTip
                attrToolTip={{
                  placement: 'top',
                  isOpen: tooltip,
                  target: data[type].id,
                  innerClassName: 'font14 font-outfit font-dark',
                  toggle: toggle,
                }} >
                {data[type].tip}
              </ToolTip>
            </div>
          </div>
          <CountUp
            start={0}
            end={balance}
            decimals={2}
            prefix='₦ '
            duration={2}
            style={{
              margin: '36px 0',
              fontSize: '38px',
              fontWeight: 600,
              color: COLORS.normal,
            }}
          />
          <H6 attrH6={{ className: 'm-t-10 font-outfit font16', style: {color: data[type].id == 'terms' ? COLORS.normal : 'transparent' } }}>{'+3%'}</H6>
        </div>
      </CardBody>
    </Card>
  );
};

export default WalletWidget;
