import React, { Fragment, useState } from 'react'
import DataTable from 'react-data-table-component';
import { TrendingDown, TrendingUp } from 'react-feather';

import { Badges, H3, H6 } from '../../../AbstractElements';
import { convertDateTime, formatCurrency } from '../../../Library/custom_utils';
import { cancelWithdrawRequestAPI } from '../../../Library/apis';
import { Spinner } from 'reactstrap';

const tableColumns = [
    {
        name: 'Status',
        selector: row => row.raw_status,
        format: row => row['status'],
        center: false,
        width: '150px',
        sortable: true,
    },
    {
        name: 'Date/Time',
        selector: row => row.raw_startDate,
        center: true,
        sortable: true,
        format: row => row['startDate']
    },
    {
        name: 'Amount',
        selector: row => row.raw_amount,
        format: row => row['amount'],
        center: true,
        sortable: true,
    },
    {
        name: 'Bank Name',
        selector: row => row.raw_bank_name,
        format: row => row['bank_name'],
        center: true,
        sortable: true,
    },
    {
        name: 'Bank Account',
        selector: row => row.raw_bank_account,
        format: row => row['bank_account'],
        center: true,
        sortable: true,
    },
    {
        name: 'Wallet Balance',
        selector: row => row.raw_totalAmount,
        format: row => row['totalAmount'],
        center: true,
        sortable: true,
    },
    {
        name: 'Action',
        selector: row => row['action'],
        center: true,
        sortable: true,
    },
];

const customStyles = {
    header: {
        style: {
            borderBottom: '0px !important',
            fontSize: '20px !important'
        },
    },
    rows: {
        style: {
            borderBottom: '0px !important',
            padding: '5px 0px'
        },
    },
};

const TransactionTableComponent = ({ data, updatePaymentHistory }) => {

    const [isActionLoading, setIsActionLoading] = useState({});

    const handleCancel = (id) => {
        setIsActionLoading(prev => ({ ...prev, [id]: true }));
        cancelWithdrawRequestAPI({ id: id }).then((res) => {
            updatePaymentHistory && updatePaymentHistory();
        }).catch((err) => {
            updatePaymentHistory && updatePaymentHistory();
        }).finally(() => {
            setIsActionLoading(prev => ({ ...prev, [id]: false }));
        });
    };

    const renderItem = (item) => {
        const badgeColor = item?.status == 'Pending' ? 'info' : item?.status == 'Success' ? 'success' : item?.status == 'Canceled' ? 'warning' : 'danger';
        const status = item?.status == 'Pending' ? 'In progress' : item?.status == 'Success' ? 'Successful' : item?.status;
        const bankName = item?.details?.data?.sourceInformation?.bankName || item?.details?.bankName || item?.bankName || 'Access Bank';
        const accountNumber = item?.details?.data?.sourceInformation?.sourceAccountNumber || item?.details?.beneficiaryAccountNumber || item?.details?.accountNumber || '0000000000';
        const type = item?.type == 'Withraw' ? 'Withdraw' : 'Deposit';

        return {

            raw_status: status,
            raw_startDate: item.createdAt,
            raw_amount: item.amount,
            raw_bank_name: bankName,
            raw_bank_account: accountNumber,
            raw_totalAmount: item.totalAmount,

            status:
                < div className={`bg-${badgeColor}`} style={{ height: '30px', minWidth: '100px', borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <H6 attrH6={{ className: 'f-14 mb-0', style: { color: 'white' } }}>{status}</H6>
                </div >,
            startDate: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDateTime(item.createdAt)}</H6>,
            amount:
                <div className='d-flex'>
                    {item.type == 'Withraw' ? <TrendingDown className='font-danger me-2' /> : <TrendingUp className='font-success me-2' />}
                    <div>
                        <H6 attrH6={{ className: 'f-14 mb-2' }}>{type}</H6>
                        <H3 attrH3={{ className: 'f-14 mb-0 f-light' }}>{formatCurrency(item.amount)}</H3>
                    </div>
                </div>,
            bank_name: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{bankName}</H6>,
            bank_account: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{accountNumber}</H6>,
            totalAmount: <span className="f-w-700 font-success f-25"><i className="icofont icofont-arrow-up"></i>{formatCurrency(item.totalAmount)}</span>,
            action:
                <div style={{ cursor: 'pointer' }}>
                    <div className='mothrift-button' onClick={() => {
                        !isActionLoading[item._id] && handleCancel(item._id)
                    }}>
                        <Badges attrBadge={{ className: 'badge rounded-pill p-2', color: 'warning', pill: true, style: { minWidth: 70 } }}>
                            {isActionLoading[item._id] ? (
                                <Spinner size='sm' color='light' />
                            ) :
                                ('Cancel')}
                        </Badges>
                    </div>
                </div>,
        }
    }

    return (
        <Fragment>
            <DataTable
                data={data.filter(item => item.status == 'Pending').map(item => renderItem(item))}
                columns={tableColumns}
                striped
                center
                customStyles={customStyles}
            />
        </Fragment>
    )
}

export default TransactionTableComponent