import React, { useContext, useEffect, useState } from 'react';
import { Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Form, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';

import { Btn, H1, H3, P } from '../../../AbstractElements';
import { setFixAPI } from '../../../Library/apis';
import UserContext from '../../../_helper/User';
import { isProd } from '../../../Library/custom_utils';

const CreateFixModal = ({ modal, toggle }) => {

    const userContext = useContext(UserContext);
    const [fixData, setFixData] = useState({
        amount: '',
        term: '0'
    })
    const [loading, setLoading] = useState(false);

    const handleSelectChange = (e) => {
        setFixData({
            ...fixData,
            term: e.target.value
        });
    };

    const handleMakeFix = () => {

        if (parseFloat(fixData.amount?.replaceAll(',', '')) <= 0) {
            toast.warning('Input your saving balance')
            return;
        }

        if (fixData.term == '0') {
            toast.warning('Select your duration');
            return;
        }

        toggle();

        setLoading(true);
        setFixAPI({ amount: parseFloat(fixData.amount.replaceAll(',', '')), term: fixData.term }).then((res) => {
            toast.success(res.message, { autoClose: 5000 });
            userContext.updateUserWalletInfo();
            userContext.updateUserAvailableTerms();
            userContext.updateUserTermSavingsInfo();
            setFixData({ amount: '', term: '0' });
        })
            .catch(e => {
                const message = e.response?.data?.message || 'Something error'
                toast.error(message, { autoClose: 5000 });
            })
            .finally(() => {
                setLoading(false);
                setFixData({ amount: '', term: '0' });
            })
    };

    const handleChange = (e) => {
        let input = e.target.value;
        input = input.replace(/[^\d]*/g, '').padStart(3, '0');
        let integerPart = input.slice(0, -2);
        let decimalPart = input.slice(-2);
        let formattedInteger = parseInt(integerPart, 10).toLocaleString('en-US');
        let formattedValue = `${formattedInteger}.${decimalPart}`;
        setFixData({
            ...fixData,
            amount: formattedValue
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            e.preventDefault();
            let newValue = fixData.amount.replace(/,/g, '').slice(0, -1);
            handleChange({ target: { value: newValue } });
        }
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered size={'lg'} className='font-outfit'>
            <ModalHeader toggle={toggle} style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Row className='ms-3 me-2'>
                    <Col xs={6}>
                        <H3>{'Start a Term Savings'}</H3>
                        <P>{'Enter desired amount below:'}</P>
                    </Col>
                    <Col xs={6}>
                        <P>{'By creating this Term savings, I accept the rules of the mothrift website around saving for the chosen duration.'}</P>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Form className='theme-form crypto-form'>
                    <div className='mb-3'>
                        <div className='position-relative'>
                            <Input
                                className='form-control'
                                name='amount'
                                id='money'
                                placeholder={'Enter amount'}
                                value={fixData.amount}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                            />
                            <select
                                className='form-select crypto-select text-dark'
                                name='term'
                                onChange={handleSelectChange}
                            >
                                <option value='0'>{'Duration'}</option>
                                {!isProd() && <>
                                    <option value='1'>{'2 hours'}</option>
                                    <option value='2'>{'2 days'}</option>
                                </>}
                                <option value='30'>{'30 days'}</option>
                                <option value='90'>{'90 days'}</option>
                                <option value='180'>{'180 days'}</option>
                                <option value='365'>{'365 days'}</option>
                            </select>
                        </div>
                    </div>
                </Form>
            </ModalBody>
            <div className='me-3 ms-3 mb-3'>
                <Btn attrBtn={{ onClick: handleMakeFix, color: 'warning', className: 'btn-hover-effect w-100 ', style: { height: '40px' } }}>
                    {loading && <Spinner size="sm" color="light" className="me-2" />}{'Yes, fix now'}
                </Btn>
            </div>
        </Modal>
    );
};

export default CreateFixModal;
