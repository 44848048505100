import React from 'react';
import { Row } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import { H1 } from '../../../../AbstractElements';
import TopHeader from '../TopHeader';
import Footer from '../Footer';

import landing_bg from '../../../../assets/images/landing/landing_top_banner.jpg';

import 'swiper/css';
import '../index.css';

const TermsPage = () => {

    const sessionData = [
        {
            title: "Terms of Use",
            subTitle: "Introduction",
            texts: [
                "The Terms are to be read together by you with any terms, conditions or disclaimers provided in the pages of our website. Please review the Terms carefully. The Terms apply to all users of our website, including without limitation, users who are browsers, customers, merchants, vendors and/or contributors of content. If you access and use this website, you accept and agree to be bound by and comply with the Terms and our Privacy Policy. If you do not agree to the Terms or our Privacy Policy, you are not authorized to access our website, use any of our website’s services or submit any information on our website.",
            ],
        },
        {
            subTitle: "Use of our Website",
            texts: [
                "You agree to not use our website to conduct any activity that would constitute a civil or criminal offence or violate any law. You agree not to attempt to interfere with our website’s network or security features or to gain unauthorized access to our systems.",
            ],
        },
        {
            subTitle: "General Conditions",
            texts: [
                "You agree that we will not be liable to you or any third party for any modification, suspension or discontinuance of our website or for any service, content, feature or product offered through our website.",
            ],
        },
        {
            subTitle: "Products or Services",
            texts: [
                "All purchases through our website are subject to product availability. We may, in our sole discretion, limit or cancel the quantities offered on our website or limit the sales of our products or services to any person, household, geographic region or jurisdiction.",
            ],
        },
    ]

    return (
        <div className="landing-page">
            <div className='first-section' style={{ backgroundImage: `url(${landing_bg})`, height: 100 }}>
                <TopHeader />
            </div>

            {sessionData.map(item => <Fade right>
                <Row className="about-section" style={{ margin: '40px' }}>
                    {item.title && <H1 attrH1={{ className: 'font40-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.title}</H1>}
                    {item.subTitle && <H1 attrH1={{ className: 'font32-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.subTitle}</H1>}
                    {item.texts.map(text=><H1 attrH1={{ className: 'font24_outfit text-dark', style: {} }}>{text}</H1>)}
                </Row>
            </Fade>)}

            <Footer />

        </div >
    );
};

export default TermsPage;
