import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { H1, H2, H3, H4, H6 } from '../../../AbstractElements';
import SvgIcon from '../Component/SvgIcon';

const Widgets1 = ({ data }) => {
  return (
    <Card className='widget-1'>
      <CardBody>
        <div className='widget-content'>
          <div className={`widget-round ${data.color}`}>
            <div className='bg-round'>
              <SvgIcon className='svg-fill' iconId={`${data.icon}`} />
              <SvgIcon className='half-circle svg-fill' iconId='halfcircle' />
            </div>
          </div>
          <div>
            <H6>{data.title}</H6>
            <H3>${data.total}</H3>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Widgets1;
