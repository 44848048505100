import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import TransactionTableComponent from './TransactionTableComponent';

const Transactions = ({ data, updatePaymentHistory }) => {

  return (
    <Card className='tranaction-card' style={{ fontFamily: 'Outfit' }}>
      <CardBody>
        <div className={`fade show table-responsive recent-table transaction-table`}>
          <TransactionTableComponent data={data} updatePaymentHistory={updatePaymentHistory} />
        </div>
      </CardBody>
    </Card>
  );
};

export default Transactions;
