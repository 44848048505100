export function formatCurrency(value) {
  let num = Number(value);
  if (isNaN(num)) num = 0;
  const roundedNum = Math.floor(num * 100) / 100;
  return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(roundedNum);
}


export function formatCurrency2(value, length = 9) {
  let num = Number(value);
  if (isNaN(num)) num = 0;
  const roundedNum = Math.floor(num * 100) / 100;
  let formatted = new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(roundedNum);

  // Check the length of the formatted string
  if (formatted.length > length) {
    if (roundedNum < 1000000) {
      // Convert to K format
      formatted = new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(roundedNum / 1000) + 'K';
    } else {
      // Convert to M format
      formatted = new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(roundedNum / 1000000) + 'M';
    }
  }
  return formatted;
}

export function convertDate(inputDate) {
  // Parse the inputDate string into a Date object
  const date = new Date(inputDate);

  // Extract date components
  const year = date.getUTCFullYear();
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based

  // Format the date string as "YYYY-DD-MM"
  return `${year}-${month}-${day}`;
}

export const convertDate2 = (createdAt) => {
  const date = new Date(createdAt);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export function convertDateTime(inputDate) {
  // Parse the inputDate string into a Date object
  const date = new Date(inputDate);

  // Extract date components
  const year = date.getUTCFullYear();
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Format the date and time string as "MM/DD/YYYY hh:mm"
  return `${month}/${day}/${year} ${hours}:${minutes}`;
}

export function isLocalServer() {
  return window.location.hostname == 'localhost'
}

export function isProd() {
  return !(window.location.hostname == 'localhost' || window.location.hostname.includes('dev'))
}

export const getTimeDiff = (createdAt) => {
  const date = new Date(createdAt);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s ago`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes}m ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours}h ago`;
  } else if (diffInDays < 7) {
    return `${diffInDays}d ago`;
  } else {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options); // Example: "3 Sep 2022"
  }
};


export const getEndDate = (start, add) => {
  const startedDate = new Date(start);
  startedDate.setDate(startedDate.getDate() + parseInt(add));
  return convertDate(startedDate);
}
