import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Nav, NavItem } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { TransactionsTitle } from '../../../Constant';
import useShowClass from '../../../Hooks/useShowClass';
import TransactionTableComponent from './TransactionTableComponent';

const Transactions = ({ data }) => {
  const NavTab = ['All', 'Deposits', 'Withdrawals'];
  const [active, setActive] = useState('All');
  const [show, setShow] = useShowClass('show');
  const activeHandler = (item) => {
    setActive(item);
    setShow('');
  };

  return (
    <Card className='tranaction-card' style={{ fontFamily: 'Outfit' }}>
      <CardHeader className='card-no-border'>
        <div className='header-top'>
          <div />
          <Nav tabs className='custom-tab'>
            {NavTab.map((item, i) => (
              <NavItem key={i}>
                <Btn attrBtn={{ color: 'transparent', onClick: (e) => activeHandler(item), className: `nav-link ${active === item && 'active'}` }}>{item}</Btn>
              </NavItem>
            ))}
          </Nav>
        </div>
      </CardHeader>
      <CardBody className='pt-0'>
        <div className={`fade ${show} table-responsive recent-table transaction-table`}>
          <TransactionTableComponent active={active} data={data} />
        </div>
      </CardBody>
    </Card>
  );
};

export default Transactions;
