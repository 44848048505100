import React, { Fragment, useState } from 'react';
import { Form, FormGroup, Input, Label, Row, Col, Card, Spinner } from 'reactstrap';
import { Btn, H4, P, Image } from '../../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { signUpAPI } from '../../../../Library/apis';
import { toast } from 'react-toastify';
import { IMAGES } from '../../../../Library/theme';
import MothriftLogo from '../../Common/MothriftLogo';

const RegisterForm = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    bankVerificationNumber: '',
    referrerInfo: '',
    agreeToPolicy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      setLoading(true);
      const data = await signUpAPI(formData);
      console.log('SignUp Result: ', data);
      toast.success(`Sent Verificaton code.`, { autoClose: 5000 });

      navigate('/mothrift/auth/code-verification', { state: { formData: formData } });

    } catch (error) {
      console.log(JSON.stringify(error?.response?.data));
      toast.error(error?.response?.data?.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MothriftLogo />
      <div className='login-card'>
        <Card className='p-5 m-4' style={{ width: '600px' }}>
          <Form className='theme-form login-form' onSubmit={handleSubmit}>
            <H4 attrH4={{ className: 'text-center text-warning' }}>Create your account</H4>
            <P attrPara={{ className: 'text-center' }}>Welcom! Get started by creating a secure account</P>
            <FormGroup>
              <Label className='col-form-label m-0 pt-0 f-w-500'>Full Name</Label>
              <Row className='g-2'>
                <Col xs='6'>
                  <Input
                    className='form-control'
                    type='text'
                    required
                    placeholder='First Name'
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs='6'>
                  <Input
                    className='form-control'
                    type='text'
                    required
                    placeholder='Last Name'
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label className='col-form-label m-0 pt-0 f-w-500'>Email Address</Label>
              <Input
                className='form-control'
                type='email'
                required
                placeholder='Email Address'
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label className='col-form-label m-0 pt-0 f-w-500'>Mobile Number</Label>
              <Input
                className='form-control'
                type='tel'
                required
                placeholder='Phone Number'
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className='position-relative'>
              <Label className='col-form-label m-0 pt-0 f-w-500'>Password</Label>
              <div>
                <Input
                  className='form-control'
                  type={togglePassword ? 'text' : 'password'}
                  name='password'
                  required
                  placeholder='Password'
                  value={formData.password}
                  onChange={handleChange}
                />
                <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                  <span className={`text-warning ${togglePassword ? '' : 'show'}`}></span>
                </div>
              </div>
            </FormGroup>

            <FormGroup className='position-relative'>
              <Label className='col-form-label m-0 pt-0 f-w-500'>Confirm Password</Label>
              <div>
                <Input
                  className='form-control'
                  type={togglePassword ? 'text' : 'password'}
                  name='confirmPassword'
                  required
                  placeholder='Confirm Password'
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                  <span className={`text-warning ${togglePassword ? '' : 'show'}`}></span>
                </div>
              </div>
            </FormGroup>

            <FormGroup>
              <Label className='col-form-label m-0 pt-0 f-w-500'>Referrer Info (Optional)</Label>
              <Input
                className='form-control'
                type='text'
                placeholder='Referrer Phone Number or UserID'
                name="referrerInfo"
                value={formData.referrerInfo}
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup className='d-flex justify-content-center'>
              <Btn attrBtn={{ color: 'warning', type: 'submit', style: { width: '100%' } }} >
                {loading ? (<> <Spinner size="sm" color="light" />{'CREATE ACCOUNT'}</>) : ("CREATE ACCOUNT")}
              </Btn>
            </FormGroup>

            <P attrPara={{ className: 'mb-0 text-center text-black' }}>
              Already have an account?
              <Link className='ms-2 text-warning f-w-600' to={`/login`}>
                Log In
              </Link>
            </P>
          </Form>
        </Card>
      </div >
    </Fragment >
  );
};

export default RegisterForm;
