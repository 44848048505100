import Context from './index';
import React, { useEffect, useState } from 'react';

const TableProvider = (props) => {
    const [data, setData] = useState([]);

    return (
        <Context.Provider
            value={{
                ...props,
                data
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default TableProvider;