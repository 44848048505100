import React, { useEffect, useState } from 'react';
import Context from './index';

const SearchResultProvider = (props) => {
    const [tabsData, setTabsData] = useState('');

    return (
        <Context.Provider
            value={{
                ...props,
                tabsData
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default SearchResultProvider;
