import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { P } from '../../../AbstractElements';

export const WidgetsRadialChart = {
  series: [],
  options: {
    chart: {
      height: 150,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '50%',
        },
        track: {
          strokeWidth: '100%',
          opacity: 1,
          margin: 5,
        },
        dataLabels: {
          showOn: 'always',
          value: {
            color: 'var(--chart-text-color)',
            fontSize: '13px',
            fontFamily: 'Outfit',
            show: true,
            offsetY: -8,
          },
          total: {
            show: true,
          }
        },
      },
    },
    colors: [],
    stroke: {
      lineCap: 'round',
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
  },
};

const TermCardChart = ({ chartOption, chartHeight, series, daysLeft, hoursLeft }) => {

  const updatedOption = {
    ...WidgetsRadialChart,
    series: series,
    options: {
      ...WidgetsRadialChart.options,
      plotOptions: {
        ...WidgetsRadialChart.options.plotOptions,
        radialBar: {
          ...WidgetsRadialChart.options.plotOptions.radialBar,
          dataLabels: {
            ...WidgetsRadialChart.options.plotOptions.radialBar.dataLabels,
            value: {
              ...WidgetsRadialChart.options.plotOptions.radialBar.dataLabels.value,
              show: false,
              color: chartOption.chart.color,
              fontWeight: 700
            },
            total: {
              ...WidgetsRadialChart.options.plotOptions.radialBar.dataLabels.total,
              show: false,
              formatter: function () {
                if (hoursLeft) return `${hoursLeft} mins\nleft`;
                return `${daysLeft} days\nleft`;
              }
            }
          }
        }
      },
      colors: [chartOption.chart.color],
    },
  };
  return (
    <div style={{ position: 'relative' }}>
      <ReactApexChart type='radialBar' height={chartHeight} series={updatedOption.series} options={updatedOption.options} />
      <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, display: 'flex', justifyContent: 'center' }}>
        <div style={{ fontSize: '13px', fontFamily: 'Outfit', fontWeight: 700, color: chartOption.chart.color, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <P attrPara={{ className: 'm-0 p-0' }}>{hoursLeft ? `${hoursLeft} mins` : `${daysLeft} days`}</P>
          <P attrPara={{ className: 'm-0 p-0' }}>{'left'}</P>
        </div>
      </div>
    </div>
  );
};


export default TermCardChart;
