import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component';
import { Badges, H4, H6 } from '../../../AbstractElements';
import { convertDate, convertDate2, formatCurrency, getEndDate } from '../../../Library/custom_utils';
import './index.css';

const tableColumns = [
    {
        name: 'Status',
        selector: row => row.raw_status,
        format: row => row['status'],
        sortable: true,
        center: false,
    },
    {
        name: 'Duration',
        selector: row => row.raw_term,
        format: row => row['term'],
        sortable: true,
        center: true,
    },
    {
        name: 'Amount',
        selector: row => row.raw_amount,
        format: row => row['amount'],
        sortable: true,
        center: true,
    },
    {
        name: 'Start',
        selector: row => row.raw_startDate,
        format: row => row['startDate'],
        sortable: true,
        center: true,
    },
    {
        name: 'End',
        selector: row => row.raw_endDate,
        format: row => row['endDate'],
        sortable: true,
        center: true,
    },
];

const TransactionTableComponent = ({ data, active }) => {

    const renderItem = (item) => {
        const statusRoundColor = item.status == 'Started' ? 'primary' : item.status == 'Finished' ? 'success' : 'secondary';
        const term = `${item.term == 1 ? '2' : item.term} ${item.term == 1 ? 'hours' : 'days'}`;
        const endDate = item.status == 'Started' ? convertDate2(getEndDate(item.createdAt, item.term == 1 ? 0 : item.term)) : convertDate2(item.updatedAt);
        const status = item.status == 'Started' ? 'Active' : item.status == 'Finished' ? 'Completed' : 'Canceled';
        return {

            raw_status: status,
            raw_term: term,
            raw_amount: item.amount,
            raw_startDate: item.createdAt,
            raw_endDate: endDate,

            status: <div className={`bg-${statusRoundColor}`} style={{ height: '30px', minWidth: '100px', borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <H6 attrH6={{ className: 'f-14 mb-0', style: { color: 'white' } }}>{status}</H6>
            </div>,
            term: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{term}</H6>,
            amount: <H4 attrH4={{ className: `font16-bold font-${statusRoundColor}` }} >{formatCurrency(item.amount)}</H4>,
            startDate: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDate2(item.createdAt)}</H6>,
            endDate: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{endDate}</H6>,
        }
    }

    const customStyles = {
        header: {
            style: {
                borderBottom: '0px !important',
                fontSize: '20px !important'
            },
        },
        rows: {
            style: {
                borderBottom: '0px !important',
                padding: '5px 0px'
            },
        },
    };

    return (
        <Fragment>
            <DataTable
                data={data.filter(item => {
                    if (active == 'All') return true;
                    return item.status.includes(active == 'Completed' ? 'Finished' : active)
                }).map(item => renderItem(item))}
                columns={tableColumns}
                striped
                center
                pagination
                paginationPerPage={20}
                style={{ fontFamily: 'Outfit' }}
                customStyles={customStyles}
            />
        </Fragment>
    )
}

export default TransactionTableComponent