import React, { Fragment, useState } from 'react';
import { useContext, useEffect } from 'react';
import { Input, Row, Spinner, Col, Card, CardBody, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { Breadcrumbs } from '../../../AbstractElements';
import { Btn, H4, H6, P } from '../../../AbstractElements';
import { getAirtimeHistoryAPI, purchaseAirtimeAPI } from '../../../Library/apis';
import UserContext from '../../../_helper/User';
import { formatCurrency } from '../../../Library/custom_utils';
import Transactions from './Transactions';
import { useParams } from 'react-router';

const BuyAirtime = () => {

  const { userWalletInfo, updateUserWalletInfo, userInfo, setIsTransfer, isTransfer } = useContext(UserContext);
  const savingsBalance = userWalletInfo.savings;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userAirtimeHistory, setUserAirtimeHistory] = useState([]);
  const [selectedOption, setSelectedOption] = useState('self');
  const { id } = useParams();

  const isValid = () => {
    if (savingsBalance <= 2000) {
      toast.error(`Savings Balance should be larger than ${formatCurrency(2000)}`, { autoClose: 5000 });
      return false;
    }

    if ((phoneNumber.length == 0 || phoneNumber.length < 10) && selectedOption == 'other') {
      toast.error(`Phone Number Validation Error, pleae input valid phone number`, { autoClose: 5000 });
      return false;
    }

    return true;
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.name);
  };

  const handleBuyAirtime = () => {
    if (isValid()) {
      Swal.fire({
        title: 'Confirmation required',
        text: `Buy airtime now for ${selectedOption == 'self' ? userInfo.phoneNumber : phoneNumber}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Buy now'
      }).then((result) => {
        if (result.isConfirmed) {
          setIsTransfer(true);
          setPhoneNumber('');
          purchaseAirtimeAPI({ phoneNumber: selectedOption == 'self' ? userInfo.phoneNumber : phoneNumber, termSavingsId: id })
            .then(() => {
              updateUserWalletInfo();
              updateAirtimeHistory();
            })
            .catch((error) => {
              const message = error?.response?.data?.message || "Something went wrong with purchase airtime"
              toast.error(message, { autoClose: 5000 });
            }).finally(() => {
              setIsTransfer(false);
            })
        }
      })
    }
  };

  const handleChange = (e) => {
    let input = e.target.value;
    setPhoneNumber(input);
  };

  const updateAirtimeHistory = () => {
    getAirtimeHistoryAPI().then(res => {
      setUserAirtimeHistory(res.body || []);
    }).catch(() => { })
  }

  useEffect(() => {
    updateAirtimeHistory();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Buy Airtime' />
      <Col xxl='4' xl='6' className='box-col-4 font-outfit'>
        <Card>
          <CardBody className='p-1'>
            <H4 attrH4={{ className: "card-title mb-4 ms-3 mt-0 font-outfit font22" }}>{`Available Savings Balance: ${formatCurrency(savingsBalance)}`}</H4>
            <Row>
              <Col xs={12} className='mb-3'>
                <Label className="d-flex font16 align-items-center" for="edo-ani">
                  <Input
                    className="radio_animated"
                    id="edo-ani"
                    type="radio"
                    name="self"
                    onChange={handleRadioChange}
                    checked={selectedOption === "self"}
                  />
                  {`Buy for self (${userInfo.phoneNumber})`}
                </Label>
              </Col>
              <Col xs={8}>
                <Label className="d-flex font16 align-items-center" for="edo-ani1">
                  <Input
                    className="radio_animated"
                    id="edo-ani1"
                    type="radio"
                    name="other"
                    onChange={handleRadioChange}
                    checked={selectedOption === "other"}
                  />
                  {"Buy for another phone number"}
                </Label>
                <Input
                  className='form-control font16 ms-4 mt-3'
                  name='phone'
                  type='number'
                  id='phone'
                  disabled={selectedOption === 'self'}
                  placeholder={'Input phone number'}
                  value={phoneNumber}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <div className='d-flex justify-content-center mt-3 ms-3 me-3'>
              <Btn attrBtn={{ className: 'w-100 font18', color: 'warning', style: { height: 40 }, onClick: handleBuyAirtime, disabled: isTransfer }}>
                {isTransfer && <Spinner size="sm" color="light" className="me-2" />}
                {isTransfer ? 'Processing. Please wait' : `Buy Airtime (${formatCurrency(2000)})`}
              </Btn>
            </div>
          </CardBody>
        </Card>
      </Col>
      {userAirtimeHistory.length > 0 && <Transactions data={userAirtimeHistory} />}
    </Fragment>
  );
};

export default BuyAirtime;