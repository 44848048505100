import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, CardHeader, CardBody, Form, FormGroup, Label, Input, Button, Spinner, Container } from 'reactstrap'
import { toast } from "react-toastify";
import Swal from 'sweetalert2';

import { H5, P, Breadcrumbs } from "../../../../AbstractElements";
import UserContext from "../../../../_helper/User";
import { changePasswordAPI, set2FAAPI } from "../../../../Library/apis";

const Security = () => {

  const { userInfo, updateUserInfo } = useContext(UserContext);
  const [isLoading, setIsloading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [isPhoneEnabled, setIsPhoneEnabled] = useState(!!(userInfo.phone2FAEnabled));
  const [isEmailEnabled, setIsEmailEnabled] = useState(!!(userInfo.email2FAEnabled));

  useEffect(() => {
    setIsPhoneEnabled(!!(userInfo.phone2FAEnabled));
    setIsEmailEnabled(!!(userInfo.email2FAEnabled));
  }, [userInfo])

  const [passwordValues, setPasswordValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  })

  const handleUpdate2FA = () => {
    setIsUpdating(true);
    const body = { phone2FAEnabled: isPhoneEnabled, email2FAEnabled: isEmailEnabled };
    set2FAAPI(body).then((res) => {
      toast.success(res.message, { autoClose: 5000 });
      updateUserInfo();
      setIsUpdating(false);
    }).catch((error) => {
      const message = error?.response?.data?.message || 'Update 2FA Error'
      toast.error(message, { autoClose: 5000 });
      setIsUpdating(false);
    });
  }

  const handleEditPassword = () => {
    if (!passwordValues.oldPassword) toast.warning('Old Password is empty.', { autoClose: 5000 })
    else if (!passwordValues.newPassword) toast.warning('New Password is empty.', { autoClose: 5000 })
    else if (!passwordValues.confirmPassword) toast.warning('Confirm Password is empty.', { autoClose: 5000 })
    else if (passwordValues.newPassword !== passwordValues.confirmPassword) toast.warning('Password is not match', { autoClose: 5000 })
    else {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change it!'
      }).then((result) => {
        if (result.isConfirmed) {
          setIsloading(true);
          changePasswordAPI({ oldPassword: passwordValues.oldPassword, newPassword: passwordValues.newPassword }).then((res) => {
            toast.success(res.message, { autoClose: 5000 });
            setIsloading(false);
          }).catch(e => {
            toast.error('Password Change Error!', { autoClose: 5000 });
            setIsloading(false);
          });
        }
      })
    }
  }

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPasswordValues(({ ...passwordValues, [name]: value }));
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Security' parent='Users' title='Edit Profile' />
      <Container fluid={true}>
        <div className='edit-profile'>
          <Col xl='12'>
            <Form className="card font-outfit">
              <CardHeader>
                <H5>{'Update your password or set up two-factor authentication to secure your account'}</H5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="5" className="p-4">
                    <FormGroup style={{ maxWidth: '350px' }} className='mb-3'>
                      <Label className='form-label'>{'Old Password'}</Label>
                      <Input className='form-control' type='password' name="oldPassword" value={passwordValues.oldPassword} onChange={handlePasswordChange} />
                    </FormGroup>
                    <FormGroup style={{ maxWidth: '350px' }} className='mb-3'>
                      <Label className='form-label'>{'New Password'}</Label>
                      <Input className='form-control' type='password' name="newPassword" value={passwordValues.newPassword} onChange={handlePasswordChange} />
                    </FormGroup>
                    <FormGroup style={{ maxWidth: '350px' }} className='mb-3'>
                      <Label className='form-label'>{'Confirm Password'}</Label>
                      <Input className='form-control' type='password' name="confirmPassword" value={passwordValues.confirmPassword} onChange={handlePasswordChange} />
                    </FormGroup>
                    <Button color="warning" onClick={handleEditPassword} className="me-3">
                      {isLoading ? (<><Spinner size="sm" color="light" className="me-2" />{' Loading...'}</>) : ('Change Password')}
                    </Button>
                  </Col>
                  <Col md="5" className="p-4">
                    <div style={{ flex: 1, backgroundColor: '#fff' }}>
                      <Container style={{ padding: 20 }}>
                        <Row>
                          <Col>
                            <P attrPara={{ style: { color: '#808080' } }} >{'To help secure your account, please choose whether to receive a verification code to your email or mobile phone for every login.'}</P>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                              <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="phoneSwitch"
                                  name="phoneSwitch"
                                  onChange={() => setIsPhoneEnabled(!isPhoneEnabled)}
                                  checked={isPhoneEnabled}
                                  style={{ marginRight: 10 }}
                                />
                                {'Receive code via registered phone number'}
                              </label>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                              <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <input
                                  type="checkbox"
                                  id="emailSwitch"
                                  name="emailSwitch"
                                  onChange={() => setIsEmailEnabled(!isEmailEnabled)}
                                  checked={isEmailEnabled}
                                  style={{ marginRight: 10 }}
                                />
                                {'Receive code via registered email'}
                              </label>
                            </div>
                            <Button color="warning" onClick={handleUpdate2FA} className="me-3">
                              {isUpdating && <Spinner size="sm" color="light" className="me-2" />}
                              {'Update'}
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Form>
          </Col>
        </div>
      </Container>
    </Fragment>
  );
};
export default Security;
