import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Label, Row } from 'reactstrap';
import UserContext from '../../../../_helper/User';
import primaryCardBack from '../../../../assets/images/payment/bank_card_primary.svg';
import addImage from '../../../../assets/images/payment/bank_card_add.svg';
import { Btn, Image } from '../../../../AbstractElements';

const BankCard = ({ setBasicLineTab }) => {

  const { userInfo, bankList } = useContext(UserContext);
  const [accountNumber, setAccountNumber] = useState(userInfo?.bankDetails?.accountNumber || '');
  const [bankName, setBankName] = useState(userInfo?.bankDetails?.bankDestinationCode || '000001');
  const [bankVerificationNumber, setBankVerificationNumber] = useState(userInfo?.bankVerificationNumber || '');

  useEffect(() => {
    setAccountNumber(userInfo?.bankDetails?.accountNumber || '');
    setBankName(userInfo?.bankDetails?.bankDestinationCode || '000001');
    setBankVerificationNumber(userInfo?.bankVerificationNumber || '');
  }, [userInfo]);

  const handleEdit = () => {
    setBasicLineTab('2');
  }

  return (
    <Fragment>
      {accountNumber &&
        <Col lg='6' xxl='4' className='box-col-6'>
          <Card style={{
            borderRadius: 10,
            backgroundImage: `url(${primaryCardBack})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            cursor: 'pointer'
          }}
            onClick={handleEdit}
          >
            <Row className='p-2'>
              <Label className="text-light font36 font-outfit">{bankList.find(bank => bank.value === bankName)?.label}</Label>
              <Label className="text-light font12 font-outfit">{'Account Number'}</Label>
              <Label className="ms-2 text-light font26 font-outfit">{accountNumber}</Label>
              <Label className="text-light font12 font-outfit">{'Bank Verification Number'}</Label>
              <Label className="ms-2 text-light font28 font-outfit">{bankVerificationNumber}</Label>
              <Label className="ms-2 text-light font18 text-end font-outfit" style={{ position: 'absolute', bottom: 10, right: 10 }} >{'Click to edit'}</Label>
            </Row>
          </Card>
        </Col>}
      {
        !accountNumber &&
        <Col lg='6' xxl='3' className='box-col-6'>
          <Card style={{ cursor: 'pointer', minHeight: '200px', justifyContent: 'center', borderRadius: 10 }} onClick={handleEdit}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <Image attrImage={{ src: `${addImage}`, alt: '', style: { width: 80 } }} />
              <Label className="font20 font-outfit mt-2">{'Add Bank Account'}</Label>
            </div>
          </Card>
        </Col>
      }
    </Fragment >
  );
};

export default BankCard;
