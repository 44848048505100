import { Btn, H4 } from '../../../../AbstractElements';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import HeaderCard from '../../../Common/Component/HeaderCard';
import { checkBankAccountValidationAPI, updateUserAPI } from '../../../../Library/apis';
import UserContext from '../../../../_helper/User';
import { toast } from 'react-toastify';

const NetBankings = ({ setBasicLineTab }) => {

  const { userInfo, updateUserInfo, bankList } = useContext(UserContext);
  const [accountNumber, setAccountNumber] = useState(userInfo?.bankDetails?.accountNumber || '');
  const [bankName, setBankName] = useState(userInfo?.bankDetails?.bankDestinationCode || '000001');
  const [bankVerificationNumber, setBankVerificationNumber] = useState(userInfo?.bankVerificationNumber || '');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAccountNumber(userInfo?.bankDetails?.accountNumber || '');
    setBankName(userInfo?.bankDetails?.bankDestinationCode || '000001');
    setBankVerificationNumber(userInfo?.bankVerificationNumber || '');
  }, [userInfo]);

  const handleSubmit = () => {
    setIsLoading(true);
    checkBankAccountValidationAPI({
      accountNumber: accountNumber, destinationCode: bankName
    }).then(() => {
      updateUserAPI({
        bankDetails: { accountNumber, bankDestinationCode: bankName }, bankVerificationNumber: bankVerificationNumber
      }).then((res) => {
        toast.success(res.message, { autoClose: 5000 })
        handleBack()
        updateUserInfo()
      }).catch((error) => {
        const message = error?.response?.data?.message || 'Update Bank Detail'
        toast.error(message, { autoClose: 5000 });
      }).finally(() => { setIsLoading(false) });
    }).catch((error) => {
      const message = error?.response?.data?.message || 'Invalid Bank Account'
      toast.error(message, { autoClose: 5000 });
    }).finally(() => { setIsLoading(false) });
  };

  const handleBack = () => {
    setBasicLineTab('1');
  }

  return (
    <Fragment>
      <Col lg='6' xxl='4' className='box-col-6'>
        <Card>
          <div className="d-flex mt-3 ms-3">
            <Btn attrBtn={{ className: 'p-0 m-0 pb-3', color: 'transparent', style: { borderWidth: 0 }, onClick: handleBack }} >
              <i className="fa fa-angle-left" style={{ color: 'black', fontSize: 30, fontWeight: 100 }}></i>
            </Btn>
            <H4 attrH4={{ className: "card-title mb-0 ms-3 mt-1" }}>{'Set Bank Information'}</H4>
          </div>
          <CardBody>
            <Row>
              <FormGroup className='mb-3 col-12'>
                <Label className="form-label">{'Account Number *'}</Label>
                <Input className='form-control' type='text' placeholder='Account number' value={accountNumber} onChange={e => setAccountNumber(e.target.value)} />
              </FormGroup>
              <FormGroup className='mb-3 col-12'>
                <Label className="form-label">{'Bank Name *'}</Label>
                <select className='form-control' size='1' value={bankName} onChange={e => setBankName(e.target.value)}>
                  {bankList.length > 0 && bankList.map((item, i) => (
                    <option value={item.value} key={i}>{item.label}</option>
                  ))}
                </select>
              </FormGroup>
              <FormGroup className='mb-3 col-12'>
                <Label className="form-label">{'Bank Verification Number'}</Label>
                <Input className='form-control' type='text' placeholder='Bank Verification Code' value={bankVerificationNumber} onChange={e => setBankVerificationNumber(e.target.value)} />
              </FormGroup>
              <div className='col-12 d-flex justify-content-end'>
                <Btn attrBtn={{ className: 'd-block w-100 mt-2', color: 'warning', onClick: handleSubmit }}>
                  {isLoading ? (<><Spinner size="sm" color="light" />{' Saving...'}</>) : ("Save")}
                </Btn>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default NetBankings;
