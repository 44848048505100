import React from 'react';
import './index.css';
import { Button, Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { useNavigate } from 'react-router';
import { Image } from '../../../AbstractElements';
import landing_bg from '../../../assets/images/brand/logo_yellow.png';

const TopHeader = () => {

    const navigate = useNavigate();
    const handleNavigate = (path) => navigate(path);

    const handleCreateAccount = () => {
        handleNavigate('/mothrift/auth/sign-up');
    }

    return (
        <div className='font-outfit'>
            <Navbar light expand="md">
                <NavbarBrand className="font32_outfit text-light p-t-30" href="/">
                    <Image attrImage={{ className: 'p-0', src: landing_bg, alt: 'shield', style: { width: 150 } }} />
                </NavbarBrand>
                <Nav className="ml-auto w-100 " navbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='font16_outfit mt-3 nav-container'>
                        <NavItem>
                            <NavLink className='text-light font-outfit' href="#home" onClick={() => { navigate('/home') }} >Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='text-light font-outfit' href="#savings" onClick={() => { navigate('/home') }}>Savings</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='text-light font-outfit' href="#features" onClick={() => { navigate('/home') }}>Features</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='text-light font-outfit' style={{ cursor: 'pointer' }} onClick={() => { navigate('/faq') }}>FAQs</NavLink>
                        </NavItem>
                    </div>

                    <div className='font16_outfit mt-3 register-container'>
                        <NavItem>
                            <NavLink className='text-light mt-1 font-outfit' href="/login" onClick={(e) => {
                                e.preventDefault();
                                handleNavigate('/login');
                            }}>Log In</NavLink>
                        </NavItem>
                        <Button className="font16_outfit signup-btn" color="warning" onClick={handleCreateAccount}>{'Sign Up - FREE'}</Button>
                    </div>

                </Nav>
            </Navbar>
        </div>
    );
};

export default TopHeader;
