import React, { Fragment, useState } from 'react'
import DataTable from 'react-data-table-component';
import { H3, H6 } from '../../../AbstractElements';
import { convertDateTime, formatCurrency } from '../../../Library/custom_utils';

const tableColumns = [
    {
        name: 'Date/Time',
        selector: row => row.raw_startDate,
        center: false,
        sortable: true,
        format: row => row['startDate']
    },
    {
        name: 'Phone Number',
        selector: row => row.raw_phone_number,
        format: row => row['phone_number'],
        center: true,
        sortable: true,
    },
    {
        name: 'Amount',
        selector: row => row.raw_amount,
        format: row => row['amount'],
        center: true,
        sortable: true,
    },
    {
        name: 'Total Balance',
        selector: row => row.raw_total_balance,
        format: row => row['total_balance'],
        center: true,
        sortable: true,
    },
    {
        name: 'Bonus',
        selector: row => row.raw_bonus,
        format: row => row['bonus'],
        center: true,
        sortable: true,
    },
];

const customStyles = {
    header: {
        style: {
            borderBottom: '0px !important',
            fontSize: '20px !important'
        },
    },
    rows: {
        style: {
            borderBottom: '0px !important',
            padding: '5px 0px'
        },
    },
};

const TransactionTableComponent = ({ data }) => {

    const renderItem = (item) => {
        return {
            raw_startDate: item.createdAt,
            raw_amount: item.amount,
            raw_phone_number: item.phoneNumber,
            raw_total_balance: item.totalBalance,
            raw_bonus: item.bonus,

            startDate: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDateTime(item.createdAt)}</H6>,
            phone_number: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{item.phoneNumber}</H6>,
            amount: <H3 attrH3={{ className: 'f-14 mb-0 f-light' }}>{formatCurrency(item.amount)}</H3>,
            total_balance: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{formatCurrency(item.totalBalance)}</H6>,
            bonus: <span className="f-w-700 font-success f-25">
                {item.bonus > 0 && <i className="icofont icofont-arrow-up"></i>}
                {formatCurrency(item.bonus)}
            </span>,
        }
    }

    return (
        <Fragment>
            <DataTable
                data={data.map(item => renderItem(item))}
                columns={tableColumns}
                striped
                center
                pagination
                paginationPerPage={20}
                customStyles={customStyles}
            />
        </Fragment>
    )
}

export default TransactionTableComponent