import React from 'react';
import { Row } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import { H1 } from '../../../../AbstractElements';
import TopHeader from '../TopHeader';
import Footer from '../Footer';

import landing_bg from '../../../../assets/images/landing/landing_top_banner.jpg';

import 'swiper/css';
import '../index.css';

const PrivacyPage = () => {

    const sessionData = [
        {
            title: "Privacy Policy",
            subTitle: "Information We Collect",
            texts: [
                "- Contact Information: We collect contact information, which includes personal information (e.g., name, phone number, and email address) that you knowingly provide when you use our Website.",
                "- Account Details: Your bank account name, bank account number, bank verification number and bank name are collected for the purpose of making payments to your bank account only.",
                "- Call records or email records: If you call or email our customer service agents, we may keep records of those conversations for purposes of reviewing and improving customer experience."
            ],
        },
        {
            subTitle: "How Your Information Is Collected",
            texts: [
                "- We collect information directly from you, whenever you visit our Website, contact us with questions or comments, upload content to our website, mobile app or social media pages, respond to surveys.",
            ],
        },
    ]

    return (
        <div className="landing-page">
            <div className='first-section' style={{ backgroundImage: `url(${landing_bg})`, height: 100 }}>
                <TopHeader />
            </div>

            {sessionData.map(item => <Fade right>
                <Row className="about-section" style={{ margin: '40px' }}>
                    {item.title && <H1 attrH1={{ className: 'font40-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.title}</H1>}
                    {item.subTitle && <H1 attrH1={{ className: 'font32-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.subTitle}</H1>}
                    {item.texts.map(text => <H1 attrH1={{ className: 'font24_outfit text-dark', style: {} }}>{text}</H1>)}
                </Row>
            </Fade>)}

            <Footer />

        </div >
    );
};

export default PrivacyPage;
