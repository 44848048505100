import React, { useContext, useEffect, useState } from 'react';
import { Col, Input, Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { Btn, H4, H5, P } from '../../../AbstractElements';
import { transferMoneyToSavingsAPI } from '../../../Library/apis';
import UserContext from '../../../_helper/User';
import { formatCurrency } from '../../../Library/custom_utils';

const TransferModal = ({ modal, toggle }) => {

    const { userWalletInfo, updateUserWalletInfo } = useContext(UserContext);
    const maxLimit = userWalletInfo.interest;
    const [amount, setAmount] = useState('');

    useEffect(() => setAmount(''), [modal]);

    const handleChange = (e) => {
        let input = e.target.value;
        input = input.replace(/[^\d]*/g, '').padStart(3, '0');
        let integerPart = input.slice(0, -2);
        let decimalPart = input.slice(-2);
        let formattedInteger = parseInt(integerPart, 10).toLocaleString('en-US');
        let formattedValue = `${formattedInteger}.${decimalPart}`;
        setAmount(formattedValue);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            e.preventDefault();
            let newValue = amount.replace(/,/g, '').slice(0, -1);
            handleChange({ target: { value: newValue } });
        }
    };

    const isValidAmount = (amount, maxLimit) => {
        if (isNaN(amount)) {
            toast.error("Invalid input: Amount should be a number", { autoClose: 5000 });
            return false;
        }

        if (amount <= 0) {
            toast.error("Invalid input: Amount should be a positive number", { autoClose: 5000 });
            return false;
        }

        if (amount > maxLimit) {
            toast.error("Invalid input: Amount exceeds the maximum limit", { autoClose: 5000 });
            return false;
        }

        return true;
    };

    const handleTransfer = () => {

        if (isValidAmount(parseFloat(amount), parseFloat(maxLimit))) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to transfer the money?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, transfer'
            }).then((result) => {
                if (result.isConfirmed) {
                    transferMoneyToSavingsAPI({ amount: parseFloat(amount.replaceAll(',', '')) })
                        .then(() => {
                            toast.success("Transfer money to your savings wallet", { autoClose: 5000 });
                            updateUserWalletInfo();
                            toggle();
                        })
                        .catch(() => {
                            toast.error("Something went wrong with the transfer", { autoClose: 5000 });
                        })
                }
            })
        }
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered>
            <div className='m-50 d-flex flex-direction-column' style={{ flexDirection: 'column', gap: 20 }}>
                <div>
                    <H4>{'Transfer to Savings Wallet'}</H4>
                    <H5>{`Available Interest Balance: ${formatCurrency(maxLimit)}`}</H5>
                    <P>{'Enter transfer amount: '}</P>
                    <Col xs={12} className='p-0'>
                        <Input
                            className='form-control'
                            name='amount'
                            id='money'
                            type='text'
                            placeholder={'Enter amount'}
                            value={amount}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />
                    </Col>
                </div>
                <Btn attrBtn={{ color: 'warning', onClick: handleTransfer, className: 'w-100', style: { height: 40 } }}>{'Transfer'}</Btn>
            </div>
        </Modal>
    );
};

export default TransferModal;
