import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import Taptop from './TapTop';
import Header from './Header';
import Sidebar from './Sidebar';
import ThemeCustomize from '../Layout/ThemeCustomizer';
import Footer from './Footer';
import CustomizerContext from '../_helper/Customizer';
import Loader from './Loader';
import { isLocalServer } from '../Library/custom_utils';
import { useEffect } from 'react';
import { verifyCustomerTokenAPI } from '../Library/apis';
import { logOut } from '../Library/api_utils';
const AppLayout = ({ children, classNames, ...rest }) => {
  const { layout } = useContext(CustomizerContext);
  const { sidebarIconType } = useContext(CustomizerContext);

  const layout1 = localStorage.getItem('sidebar_layout') || layout;
  const sideBarIcon = localStorage.getItem('sidebar_icon_type') || sidebarIconType;
  const navigate = useNavigate();

  useEffect(() => {
    verifyCustomerTokenAPI().then(() => { }).catch(() => {
      logOut();
      toast.warning("Session is expired, please login again.");
      navigate('/login');
    });
  }, []);

  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div className={`page-wrapper ${layout1}`} sidebar-layout={sideBarIcon} id='pageWrapper'>
        <Header />
        <div className='page-body-wrapper'>
          <Sidebar />
          <div className='page-body'>
            <TransitionGroup {...rest}>
              <div>
                <Outlet />
              </div>
            </TransitionGroup>
          </div>
          <Footer />
        </div>
      </div>
      {/* {isLocalServer() && <ThemeCustomize />} */}
    </Fragment>
  );
};
export default AppLayout;
