import React, { useContext, useEffect, useState } from 'react';
import { Col, Input, Row, Modal, ModalBody, ModalFooter, ModalHeader, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { Btn, H5, H6, P } from '../../../AbstractElements';
import { bankTransferAPI } from '../../../Library/apis';
import UserContext from '../../../_helper/User';
import { useNavigate } from 'react-router';

const WithrawModal = ({ modal, toggle, updatePaymentHistory }) => {

    const { userWalletInfo, updateUserWalletInfo, userInfo } = useContext(UserContext);
    const maxLimit = userWalletInfo.savings;
    const navigate = useNavigate();
    const [amount, setAmount] = useState(0.00);

    useEffect(() => setAmount(0), [modal]);

    const isValidAmount = (amount, maxLimit) => {
        if (isNaN(amount)) {
            toast.error("Invalid input: Amount should be a number", { autoClose: 5000 });
            return false;
        }

        if (amount <= 0) {
            toast.error("Invalid input: Amount should be a positive number", { autoClose: 5000 });
            return false;
        }

        if (amount > maxLimit) {
            toast.error("Invalid input: Amount exceeds the maximum limit", { autoClose: 5000 });
            return false;
        }

        return true;
    };

    const hasBankDetails = userInfo?.bankDetails?.accountNumber && userInfo?.bankDetails?.accountNumber;

    const handleWithraw = () => {
        if (!hasBankDetails) {
            toast.warning("Please complete your bank details", { autoClose: 5000 });
            navigate('/mothrift/account-settings/payment')
        } else if (isValidAmount(amount, maxLimit)) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to Withraw the money?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Withraw it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    toggle();
                    bankTransferAPI({ amount: amount })
                        .then(() => {
                            toast.success("Withraw money to your bank account", { autoClose: 5000 });
                            updateUserWalletInfo();
                            updatePaymentHistory();
                        })
                        .catch((error) => {
                            const message = error?.response?.data?.message || "Something went wrong with the Withraw"
                            toast.error(message, { autoClose: 5000 });
                        })
                }
            })
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        navigate('/mothrift/account-settings/payment');
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader className='pb-0' toggle={toggle}>{'Withraw Money from your savings wallet'}</ModalHeader>
            <ModalBody>
                {!hasBankDetails && <H6 attrH6={{
                    className: 'text-danger f-700', style: { borderBottom: '2px solid red', cursor: 'pointer', width: 'fit-content' }, onClick: handleClick
                }}>{'You should complete your bank details'}</H6>}
                <Row className='mt-0 pt-0'>
                    <Col className='mt-0 pt-0'>
                        <Label className="d-block mb-2" for="edo-ani">
                            <Input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" defaultChecked />{'Bank Transfer'}
                        </Label>
                        <Label className="d-block mt-1 mb-2" for="edo-ani1">
                            <Input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" disabled />{'Credit Card'}
                        </Label>
                        <Label className="d-block mt-1 mb-3" for="edo-ani2">
                            <Input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" disabled />{'Debit Card'}
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3} >
                        <Btn attrBtn={{ color: 'info', className: 'w-100', onClick: () => { setAmount(0.00) } }} >{'Min'}</Btn>
                    </Col>
                    <Col xs={6} className='p-0'>
                        <Input className='form-control text-end' name='amount' id='money' type='number' placeholder={'Input your transfer amount'} value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </Col>
                    <Col xs={3} >
                        <Btn attrBtn={{ color: 'success', className: 'w-100', onClick: () => { setAmount(userWalletInfo.savings) } }} >{'Max'}</Btn>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >{'Close'}</Btn>
                <Btn attrBtn={{ color: 'primary', onClick: handleWithraw, disabled: !hasBankDetails }}>{'Withraw'}</Btn>
            </ModalFooter>
        </Modal>
    );
};

export default WithrawModal;
