import React, { useContext } from "react";
import Avatar from 'react-avatar';
import { Row, Col, CardFooter, Label, Button, Card } from 'reactstrap'

import { H3, H4, H5 } from "../../../../AbstractElements";
import UserContext from "../../../../_helper/User";

const MyProfile = ({ handleEditProfile }) => {

    const { userInfo } = useContext(UserContext);
    return (
        <Col xxl={4} xl={6} xs={12}>
            <Card>
                <Row className='mb-2 mt-4 ms-4'>
                    <div className='profile-title'>
                        <div className='media'>
                            <Avatar name={userInfo.userName} size="120" round src={userInfo.profileImageUrl} />
                            <div className='media-body'>
                                <H3 attrH3={{ className: 'mb-1' }}>{userInfo.userName || '--'}</H3>
                                {userInfo.userId && <H4>{userInfo.userId}</H4>}
                            </div>
                        </div>
                    </div>
                </Row>
                <Row className="ms-4" style={{ gap: 20 }}>
                    <Col sm="12">
                        <Label className="form-label">{'Email Address'}</Label>
                        {userInfo.email && <H5>{userInfo.email}</H5>}
                    </Col>
                    <Col sm="12">
                        <Label className="form-label">{'Phone Number'}</Label>
                        {userInfo.phoneNumber && <H5>{userInfo.phoneNumber}</H5>}
                    </Col>
                </Row>
                <CardFooter
                    className="text-end">
                    <Button color="warning" onClick={handleEditProfile} className="me-3">{'Edit Profile'}</Button>
                </CardFooter>
            </Card>
        </Col>
    )
}
export default MyProfile