import React from 'react';
import { ArrowDown, ArrowUp, Trash2 } from 'react-feather';
import { Button, Card, CardBody } from 'reactstrap';
import { H6, H5, P, Btn, H4 } from '../../../AbstractElements';
import RadialProgressChart from './RadialProgressChart';

const RadialProgressWidget = ({ data, chartHeight }) => {
  return (
    <Card className='widget-hover'>
      <CardBody className='radial-progress-card'>
        <div>
          <H4 attrH6={{ className: 'mb-0' }}>{data.title}</H4>
          <div className='sale-details'>
            <H5 attrH5={{ className: `font-${data.color} mb-0` }}>{data.average}</H5>
            <span className='f-12 f-light f-w-500'>
              {data.gros >= 1.5 ? <ArrowUp /> : <ArrowDown />}
              {data.gros >= 1.5 ? '+' : '-'}
              {data.gros}%
            </span>
          </div>
          <P attrPara={{ className: 'f-light' }}> {data.subTitle}</P>
        </div>
        <div className='radial-chart-wrap'>
          <RadialProgressChart chartHeight={chartHeight} chartOption={data} />
        </div>
        <div style={{ position: 'absolute', right: '20px', top: '20px' }}>
            <Trash2 className='me-2 badge-light-primary' />
        </div>
      </CardBody>
    </Card>
  );
};

export default RadialProgressWidget;
