import React, { Fragment, useState } from 'react';
import { useContext, useEffect } from 'react';
import { Input, Row, Spinner, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { Breadcrumbs } from '../../../AbstractElements';
import { Btn, H4, H6, P } from '../../../AbstractElements';
import { bankTransferAPI, getPaymentHistoryAPI } from '../../../Library/apis';
import UserContext from '../../../_helper/User';
import { useNavigate } from 'react-router';
import { formatCurrency } from '../../../Library/custom_utils';
import Transactions from './Transactions';

const WithrawFund = () => {

  const { userWalletInfo, updateUserWalletInfo, userInfo, setIsTransfer, isTransfer } = useContext(UserContext);
  const savingsBalance = userWalletInfo.savings;
  const navigate = useNavigate();
  const [amount, setAmount] = useState('');
  const { socket } = useContext(UserContext);
  const [userPaymentHistory, setUserPaymentHistory] = useState([]);

  const isValidAmount = (amount, maxLimit) => {
    if (isNaN(amount)) {
      toast.error("Invalid input: Amount should be a number", { autoClose: 5000 });
      return false;
    }

    if (amount <= 0) {
      toast.error("Invalid input: Amount should be a positive number", { autoClose: 5000 });
      return false;
    }

    if (amount > maxLimit) {
      toast.error("Invalid input: Amount exceeds the maximum limit", { autoClose: 5000 });
      return false;
    }
    return true;
  };

  const hasBankDetails = userInfo?.bankDetails?.accountNumber && userInfo?.bankDetails?.accountNumber;

  const handleWithraw = () => {
    setAmount('');
    if (!hasBankDetails) {
      toast.warning("Please complete your bank details", { autoClose: 5000 });
      navigate('/mothrift/account-settings/payment')
    } else if (isValidAmount(parseFloat(amount.replaceAll(',', '')), parseFloat(savingsBalance))) {
      Swal.fire({
        title: 'Confirmation required',
        text: "Are you ready to make this transfer?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Withdraw'
      }).then((result) => {
        if (result.isConfirmed) {
          setIsTransfer(true);
          bankTransferAPI({ amount: parseFloat(amount.replaceAll(',', '')) })
            .then(() => {
              updateUserWalletInfo();
            })
            .catch((error) => {
              const message = error?.response?.data?.message || "Something went wrong with the Withraw"
              toast.error(message, { autoClose: 5000 }, { autoClose: 5000 });
            }).finally(() => {
              setIsTransfer(false);
            })
        }
      })
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    navigate('/mothrift/account-settings/payment');
  };

  const handleChange = (e) => {
    let input = e.target.value;
    input = input.replace(/[^\d]*/g, '').padStart(3, '0');
    let integerPart = input.slice(0, -2);
    let decimalPart = input.slice(-2);
    let formattedInteger = parseInt(integerPart, 10).toLocaleString('en-US');
    let formattedValue = `${formattedInteger}.${decimalPart}`;
    setAmount(formattedValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      let newValue = amount.replace(/,/g, '').slice(0, -1);
      handleChange({ target: { value: newValue } });
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('message', (message) => {
        if (message.includes('bank')) {
          updatePaymentHistory();
        }
      });
    }
  }, []);

  const updatePaymentHistory = () => {
    getPaymentHistoryAPI().then(res => {
      const paymentHistory = res.body || [];
      setUserPaymentHistory(paymentHistory.filter(item => item.status == 'Pending'));
    }).catch(() => { })
  }

  useEffect(() => {
    updatePaymentHistory();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Withdraw Money' />
      {userPaymentHistory.length == 0 && <Col xxl='4' xl='6' className='box-col-4 font-outfit'>
        <Card>
          <CardBody className='p-3'>
            <H4 attrH4={{ className: "card-title mb-4 mt-0 font-outfit font22" }}>{`Available Savings Balance: ${formatCurrency(savingsBalance)}`}</H4>
            <Row>
              {!hasBankDetails && <H6 attrH6={{
                className: 'text-danger f-700 font18 mt-0 mb-2', style: { borderBottom: '1px solid red', cursor: 'pointer', width: 'fit-content', marginTop: -20, marginBottom: 10 }, onClick: handleClick
              }}>{'Please add your bank account.'}</H6>}
              <Input
                className='form-control font20'
                name='amount'
                id='money'
                placeholder={'Input your transfer amount'}
                value={amount}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </Row>
            <div className='d-flex justify-content-center mt-3'>
              <Btn attrBtn={{ className: 'w-100 font20', color: 'warning', style: { height: 45 }, onClick: handleWithraw, disabled: (!hasBankDetails || isTransfer) }}>
                {isTransfer && <Spinner size="sm" color="light" className="me-2" />}
                {isTransfer ? 'Payment processing. Please wait' : 'Initiate Withdrawal'}
              </Btn>
            </div>
          </CardBody>
        </Card>
      </Col>}
      {userPaymentHistory.length > 0 && <Transactions data={userPaymentHistory} updatePaymentHistory={updatePaymentHistory} />}
    </Fragment>
  );
};

export default WithrawFund;