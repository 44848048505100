import React, { Fragment, useState, useEffect } from 'react';
import { Image } from '../../AbstractElements';
import { IMAGES } from '../../Library/theme';

const Loader = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  return (
    <Fragment>
      <div className={`loader-wrapper ${show ? '' : 'loderhide'}`}>
        <div style={{ position: 'absolute', margin: '0px 0 200px 0', zIndex: 1 }}>
          <Image attrImage={{ className: 'p-0', src: IMAGES.logo_yellow, alt: 'shield', style: { width: 400 } }} />
        </div>
        <div className='loader-index'>
          <span></span>
        </div>
        <svg>
          <defs></defs>
          <filter id='goo'>
            <fegaussianblur in='SourceGraphic' stdDeviation='11' result='blur'></fegaussianblur>
            <fecolormatrix in='blur' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9' result='goo'></fecolormatrix>
          </filter>
        </svg>
      </div>
    </Fragment>
  );
};

export default Loader;
