import { apiGet, apiPost, apiPut } from '../api_utils';
import { API_URL, Payment_API_URL, SOCKET_URL } from '../constant';

// User Api
export const signUpAPI = data => apiPost(`${API_URL}/CustomerUser/Auth/SignUp`, data);

export const loginAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/LogIn`, data);

export const activeUserAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/ActivateUser`, data);

export const changePasswordAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/ChangePassword`, data);

export const resetPasswordAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/ResetPassword`, data);

export const getUserAPI = () => apiGet(`${API_URL}/CustomerUser/Auth/GetUser`);

export const updateUserAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/UpdateUser`, data);

export const updateSocketIdAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/UpdateSocketId`, data);

export const uploadProfileImageAPI = data => apiPost(`${SOCKET_URL}/upload`, data);

export const deleteAccountAPI = () => apiPut(`${API_URL}/CustomerUser/Auth/DeleteAccount`);

export const verifyCustomerTokenAPI = () => apiPut(`${API_URL}/CustomerUser/Auth/VerifyCustomerToken`);

export const sendVerificationRequestAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/SendVerificationRequest`, data);

export const confirmOTPCodeAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/ConfirmOTPCode`, data);

export const confirmResetPasswordAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/ConfirmResetPassword`, data);

export const set2FAAPI = data => apiPut(`${API_URL}/CustomerUser/Auth/Set2FA`, data);

export const get2FAAPI = () => apiGet(`${API_URL}/CustomerUser/Auth/Get2FA`);


// Savings
export const getWalletInfoAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetWalletInfo`);

export const getBalancesAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetBalances`);

export const setFixAPI = data => apiPut(`${API_URL}/CustomerUser/Savings/SetFix`, data);

export const cancelFixAPI = data => apiPut(`${API_URL}/CustomerUser/Savings/CancelFix`, data);

export const getTermFixesAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetTermFixs`);

export const getUserAvailableTermsAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetUserAvailableTerms`);

export const getUserNotificationsAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetUserNotifications`);

export const getPaymentHistoryAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetPaymentHistory`);

export const getAirtimeHistoryAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetAirtimeHistory`);

export const getEarnHistoryAPI = () => apiGet(`${API_URL}/CustomerUser/Savings/GetEarnHistory`);

export const transferMoneyToSavingsAPI = data => apiPut(`${API_URL}/CustomerUser/Savings/TransferMoneyToSavings`, data);

export const makeReadNotificationAPI = data => apiPut(`${API_URL}/CustomerUser/Savings/MakeReadNotification`, data);


// Payments
export const notifyEnquityAPI = data => apiPost(`${Payment_API_URL}/name_enquiry`, data);

export const notifyPaymentAPI = data => apiPost(`${Payment_API_URL}/notify_payment`, data);

export const checkBankAccountValidationAPI = data => apiPost(`${Payment_API_URL}/CheckBankAccountValidation`, data);

export const bankTransferAPI = data => apiPost(`${Payment_API_URL}/BankTransfer`, data);

export const purchaseAirtimeAPI = data => apiPost(`${Payment_API_URL}/PurchaseAirtime`, data);

export const cancelWithdrawRequestAPI = data => apiPost(`${Payment_API_URL}/CancelWithdrawRequest`, data);

export const getBankListAPI = () => apiGet(`${Payment_API_URL}/GetBankList`);
