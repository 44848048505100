import React from 'react';
import { Row } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import { H1 } from '../../../../AbstractElements';
import TopHeader from '../TopHeader';
import Footer from '../Footer';

import landing_bg from '../../../../assets/images/landing/landing_top_banner.jpg';

import 'swiper/css';
import '../index.css';

const About = () => {

    const sessionData = [
        {
            title: "ABOUT US",
            texts: [
                "Welcome to MoThrift – Your Gateway to Smart Savings!",
                "At MoThrift, we are on a mission to empower you on your financial journey. We understand that every penny counts, and that is why we have created a unique savings platform tailored just for you."
            ],
        },
        {
            title: "Who We Are:",
            texts: [
                "MoThrift is more than just a savings platform; we are your financial ally. Founded with the vision of making savings accessible to everyone, we believe in the power of smart, secure, and straightforward financial solutions.",
            ],
        },
        {
            title: "What Sets Us Apart:",
            subTitle: "Smart Savings Made Simple",
            texts: [
                "Saving doesn't have to be complicated. MoThrift simplifies the savings process, allowing you to grow your wealth without the hassle. Whether you're a seasoned saver or just starting, our platform is designed with you in mind.",
            ],
        },
        {
            subTitle: "Fixed Returns, Guaranteed",
            texts: [
                "Say goodbye to uncertainties. With MoThrift, you can save with confidence, knowing that your money is working for you. Our fixed returns ensure that your savings grow steadily, providing financial stability for your future.",
            ],
        },
        {
            subTitle: "Tailored for You",
            texts: [
                "We understand that everyone's financial goals are unique. That's why MoThrift offers personalized savings plans to suit your needs. Whether you're saving for a dream vacation, a new home, or retirement, we've got you covered.",
            ],
        },
        {
            subTitle: "How It Works",
            texts: [
                "Saving with MoThrift is as easy as 1-2-3. Simply choose your savings plan, fix your money for the specified duration, and watch your wealth grow. Our user-friendly interface and transparent processes make navigating the world of savings a breeze.",
            ],
        },
        {
            subTitle: "Join Us on Your Financial Journey",
            texts: [
                "Whether you're a seasoned saver or taking your first steps into the world of finance, MoThrift is here to guide you. Join us on this exciting financial journey, and let's build a prosperous future together.",
                "Save with MoThrift – Save for Your Tomorrow.",
            ],
        },
    ]

    return (
        <div className="landing-page">
            <div className='first-section' style={{ backgroundImage: `url(${landing_bg})`, height: 100 }}>
                <TopHeader />
            </div>

            {sessionData.map(item => <Fade right>
                <Row className="about-section" style={{ margin: '40px' }}>
                    {item.title && <H1 attrH1={{ className: 'font40-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.title}</H1>}
                    {item.subTitle && <H1 attrH1={{ className: 'font32-bold font-outfit text-dark', style: { marginTop: '20px' } }}>{item.subTitle}</H1>}
                    {item.texts.map(text=><H1 attrH1={{ className: 'font24_outfit text-dark', style: {} }}>{text}</H1>)}
                </Row>
            </Fade>)}

            <Footer />

        </div >
    );
};

export default About;
