import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component';
import { convertDate2, formatCurrency } from '../../../Library/custom_utils';
import { H6 } from '../../../AbstractElements';

const tableColumns = [
    {
        name: 'Amount',
        selector: row => row.raw_amount,
        format: row => row['amount'],
        sortable: true,
        center: false,
    },
    {
        name: 'Date',
        selector: row => row.raw_startDate,
        format: row => row['startDate'],
        sortable: true,
        center: true,
    },
    {
        name: 'Term Duration',
        selector: row => row.raw_fixType,
        format: row => row['fixType'],
        sortable: true,
        center: true,
    },
    {
        name: 'Term Starte Date',
        selector: row => row.raw_fixStarted,
        format: row => row['fixStarted'],
        sortable: true,
        center: true,
    },
    {
        name: 'Term End Date',
        selector: row => row.raw_fixFinished,
        format: row => row['fixFinished'],
        sortable: true,
        center: true,
    },
    {
        name: 'Term Amount',
        selector: row => row.raw_fixAmount,
        format: row => row['fixAmount'],
        sortable: true,
        center: true,
    }
];

const customStyles = {
    header: {
        style: {
            borderBottom: '0px !important',
            fontSize: '20px !important'
        },
    },
    rows: {
        style: {
            borderBottom: '0px !important',
            padding: '5px 0px'
        },
    },
};

const DataTableComponent = ({ data }) => {

    const renderItem = (item, i) => {
        return {
            raw_amount: item.amount,
            raw_startDate: item.date,
            raw_fixType: item.fixType,
            raw_fixStarted: item.fixStarted,
            raw_fixFinished: item.fixFinished,
            raw_fixAmount: item.fixAmount,

            amount: <H6 attrH6={{ className: 'font16-bold font-success' }}>{formatCurrency(item.amount)}</H6>,
            startDate: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDate2(item.date)}</H6>,
            fixType: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{item.fixType == 1 ? '2 hours' : `${item.fixType} days`}</H6>,
            fixStarted: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDate2(item.fixStarted)}</H6>,
            fixFinished: <H6 attrH6={{ className: 'f-14 mb-0 f-light' }}>{convertDate2(item.fixFinished)}</H6>,
            fixAmount: <span className="font16-bold font-success">{formatCurrency(item.fixAmount)}</span>,
        }
    }

    return (
        <Fragment>
            <DataTable
                data={data.map((item, i) => renderItem(item, i))}
                columns={tableColumns}
                striped
                center
                pagination
                paginationPerPage={20}
                customStyles={customStyles}
            />
        </Fragment>
    )
}

export default DataTableComponent