import React, { useState } from 'react';
import { Col, TabContent, TabPane } from 'reactstrap';
import NetBankings from './NetBanking';
import BankCard from './BankCard';

const PaymentTab = () => {
    const [BasicLineTab, setBasicLineTab] = useState('1');
    return (
        <Col sm='12' xl='12' className='xl-100'>
            <TabContent activeTab={BasicLineTab}>
                <TabPane className='fade show' tabId='1'>
                    <BankCard setBasicLineTab={setBasicLineTab} />
                </TabPane>
                <TabPane tabId='2'>
                    <NetBankings setBasicLineTab={setBasicLineTab} />
                </TabPane>
            </TabContent>
        </Col>
    );
};

export default PaymentTab;
