import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import EditmyProfile from './EditmyProfile';
import MyProfile from './MyProfile';

const ProfileTab = () => {
    const [BasicLineTab, setBasicLineTab] = useState('1');
    return (
        <Col sm='12' xl='12' className='xl-100'>
            <TabContent activeTab={BasicLineTab}>
                <TabPane className='fade show' tabId='1'>
                    <MyProfile handleEditProfile={() => {
                        setBasicLineTab('2')
                    }} />
                </TabPane>
                <TabPane tabId='2'>
                    <EditmyProfile handleBack={() => {
                        setBasicLineTab('1')
                    }} />
                </TabPane>
            </TabContent>
        </Col>
    );
};

export default ProfileTab;
