import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import WalletWidget from './WalletWidget';
import UserContext from '../../../_helper/User';

const WalletWrapper = () => {

  const { userWalletInfo } = useContext(UserContext)
  return (
    <Col xs={12}>
      <Row>
        <Col xxl='3' xl='4'>
          <WalletWidget type="savings" balance={userWalletInfo.savings} />
        </Col>
        <Col xxl='3' xl='4'>
          <WalletWidget type="terms" balance={userWalletInfo.terms} />
        </Col>
        <Col xxl='3' xl='4'>
          <WalletWidget type="interest" balance={userWalletInfo.interest} />
        </Col>
        <Col xxl='3' xl='4'>

        </Col>
      </Row>
    </Col>
  );
};

export default WalletWrapper;
