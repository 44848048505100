import React, { useContext, useState } from 'react';
import { Grid } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { H1, Image } from '../../AbstractElements';
import CubaIcon from '../../assets/images/logo/logo.png';
import CustomizerContext from '../../_helper/Customizer';
import MothriftLogo from '../../Components/Mothrift/Common/MothriftLogo';
import { IMAGES } from '../../Library/theme';

const SidebarLogo = () => {
  const { mixLayout, toggleSidebar, layout, layoutURL } = useContext(CustomizerContext);
  const [toggle, setToggle] = useState(false);

  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };

  const layout1 = localStorage.getItem('sidebar_layout') || layout;
  const navigate = useNavigate();

  return (
    <div className='logo-wrapper'>
      <div style={{ margin: '0 0 10px 10px', zIndex: 1, cursor: 'pointer' }} onClick={() => { navigate('/mothrift/home') }}>
        <Image attrImage={{ className: 'p-0', src: IMAGES.logo_yellow, alt: 'logo', style: { width: 150 } }} />
      </div>
      {/* {layout1 !== 'compact-wrapper dark-sidebar' && layout1 !== 'compact-wrapper color-sidebar' && mixLayout ? (
        <Link to={`${process.env.PUBLIC_URL}/mothrift/home`}>
          <Image attrImage={{ className: 'img-fluid d-inline', src: `${CubaIcon}`, alt: '' }} />
        </Link>
      ) : (
        <Link to={`${process.env.PUBLIC_URL}/mothrift/home`}>
          <Image attrImage={{ className: 'img-fluid d-inline', src: `${require('../../assets/images/logo/logo_dark.png')}`, alt: '' }} />
        </Link>
      )} */}
      {/* <div className='back-btn' onClick={() => openCloseSidebar()}>
        <i className='fa fa-angle-left'></i>
      </div>
      <div className='toggle-sidebar' onClick={openCloseSidebar}>
        <Grid className='status_toggle middle sidebar-toggle' />
      </div> */}
    </div >
  );
};

export default SidebarLogo;
