import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import Avatar from 'react-avatar';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap'
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import axios from 'axios';

import { Btn, H4, H5 } from "../../../../AbstractElements";
import { EditProfile } from '../../../../Constant';
import UserContext from "../../../../_helper/User";
import { deleteAccountAPI, updateUserAPI } from "../../../../Library/apis";
import { logOut } from "../../../../Library/api_utils";
import { SOCKET_URL } from "../../../../Library/constant";

const EditMyProfile = ({ handleBack }) => {

    const { userInfo, updateUserInfo } = useContext(UserContext);
    const { handleSubmit } = useForm();
    const navigate = useNavigate();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const fileInputRef = useRef();

    const [formValues, setFormValues] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        bankVerificationNumber: "",
        profileImageUrl: ""
    });

    const onEditSubmit = () => {
        // setIsUpdating(true);
        updateUserAPI(formValues).then((res) => {
            toast.success(res.message, { autoClose: 5000 });
            updateUserInfo();
            handleBack();
            // setIsUpdating(false);
        }).catch((error) => {
            const message = error?.response?.data?.message || 'Update Profile Error'
            toast.error(message, { autoClose: 5000 });
            // setIsUpdating(false);
        });
    }

    const handleDeleteAccount = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // If user confirmed, continue deleting the account
                setIsDeleting(true);
                deleteAccountAPI().then((res) => {
                    toast.success(res.message, { autoClose: 5000 })
                    logOut();
                    setIsDeleting(false);
                    navigate('/login');
                }).catch((error) => {
                    const message = error?.response?.data?.message || 'Delete Account Error'
                    toast.error(message, { autoClose: 5000 });
                    setIsDeleting(false);
                });
            }
        })
    }

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormValues(({ ...formValues, [name]: value }));
    };

    const [profileImageUrl, setProfileImageUrl] = useState(userInfo.profileImageUrl);

    const handleAvatarClick = () => {
        fileInputRef.current.click();
    };

    const readUrl = (event) => {
        if (event.target.files.length === 0) return;
        var mimeType = event.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = async (_event) => {
            setProfileImageUrl(reader.result);

            const formData = new FormData();
            formData.append('image', event.target.files[0], '1.png');
            try {
                let token = localStorage.getItem('token');
                const headers = {
                    'Authorization': `Bearer ${token}` || '',
                    'Content-Type': 'multipart/form-data',
                }
                const response = await axios.post(`${SOCKET_URL}/upload`, formData, { headers });
                setFormValues(({ ...formValues, profileImageUrl: `${SOCKET_URL}\\api\\${response.data.filePath}` }));
            } catch (error) {
                toast.error('upload error')
            }
        };
    };

    useEffect(() => {
        if (userInfo) {
            setFormValues({
                firstName: userInfo.firstName || "",
                middleName: userInfo.middleName || "",
                lastName: userInfo.lastName || "",
                phoneNumber: userInfo.phoneNumber || "",
                profileImageUrl: userInfo.profileImageUrl || "",
                email: userInfo.email || "",
                bankVerificationNumber: userInfo.bankVerificationNumber || "",
            })
        }
    }, [userInfo])

    return (
        <Fragment>
            <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
                <CardHeader>
                    <div className="d-flex">
                        <Btn attrBtn={{ className: 'p-0 m-0', color: 'transparent', style: { borderWidth: 0 }, onClick: handleBack }} >
                            <i className="fa fa-angle-left" style={{ color: 'black', fontSize: 30, fontWeight: 100 }}></i>
                        </Btn>
                        <H4 attrH4={{ className: "card-title mb-0 ms-3 mt-1" }}>{EditProfile}</H4>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Row className='mb-2'>
                            <div className='profile-title'>
                                <div className='media'>
                                    <Avatar
                                        name={userInfo.userName}
                                        value="86%"
                                        size="80"
                                        round
                                        src={profileImageUrl || userInfo.profileImageUrl}
                                        onClick={handleAvatarClick}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <input
                                        ref={fileInputRef}
                                        className='upload'
                                        style={{ display: 'none' }}
                                        type='file'
                                        onChange={(e) => readUrl(e)}
                                    />
                                    <div className='media-body'>
                                        <H4 attrH4={{ className: 'mb-1' }}>{userInfo.userName || '--'}</H4>
                                        <H5>{userInfo.userId || '--'}</H5>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Col md="4">
                            <FormGroup className="mb-3">
                                <Label className="form-label">{'First Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="First Name"
                                    name="firstName"
                                    required
                                    value={formValues.firstName || ""}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup> <Label className="form-label">{'Middle Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.middleName || ""}
                                    name="middleName"
                                    onChange={handleChange}
                                    placeholder="Middle Name"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup> <Label className="form-label">{'Sure Name'}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    value={formValues.lastName || ""}
                                    name="lastName"
                                    onChange={handleChange}
                                    placeholder="Sure Name"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Email Address'}</Label>
                                <Input
                                    className="form-control"
                                    type="email"
                                    value={formValues.email || ""}
                                    onChange={handleChange}
                                    name="email"
                                    placeholder="Email Address"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup><Label className="form-label">{'Phone Number'}</Label>
                                <Input
                                    className="form-control"
                                    type="phone"
                                    value={formValues.phoneNumber || ""}
                                    onChange={handleChange}
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    required />
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="4">
                            <FormGroup> <Label className="form-label">{'Bank Verfication Number'}</Label>
                                <Input
                                    className="form-control"
                                    type="text" value={formValues.bankVerificationNumber || ""}
                                    onChange={handleChange}
                                    name="bankVerificationNumber"
                                    placeholder="Bank Verfication Number" />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter
                    className="text-end">
                    <Button color="danger" onClick={handleDeleteAccount} className="me-3">
                        {isDeleting && <Spinner size="sm" color="light" className="me-2" />}{"Delete Account"}
                    </Button>
                    <Button color="warning" type="submit">
                        {isUpdating && <Spinner size="sm" color="light" className="me-2" />}{'Update Profile'}
                    </Button>
                </CardFooter>
            </Form>
        </Fragment >
    )
}
export default EditMyProfile