import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import ProfileTab from './ProfileTab';

const AccountSettings = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Edit Profile' parent='Users' title='Edit Profile' />
      <Container fluid={true}>
        <div className='edit-profile'>
          <Col xl='12'>
            <ProfileTab />
          </Col>
        </div>
      </Container>
    </Fragment>
  );
};
export default AccountSettings;
