import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../AbstractElements';
import EarnHistoryTable from './EarnHistoryTable';
import HeaderCard from '../../Common/Component/HeaderCard';
import WalletWrapper from '../Home/WalletWraper';
import { getEarnHistoryAPI } from '../../../Library/apis';
import TransferModal from './TransferModal';

const EarnInterest = () => {

  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    getEarnHistoryAPI().then(res => {
      setData(res.body);
    }).catch(() => {
    })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Your Earned Interest' parent='mothrift' title='Your Earn Interest' />
      <Container fluid={true}>
        <Row style={{ fontFamily: 'Outfit' }}>
          <Col xxl='12' xl='12'>
            <Row>
              <div>
                <Button className='mb-3 font18-bold font-outfit p-0' color='warning' onClick={toggle} style={{ width: '150px', height: '40px' }}><i className='fa fa-solid fa-exchange me-1' />Transfer</Button>
              </div>
              <Col xxl="12" sm='12'>
                <Card>
                  <CardBody>
                    <EarnHistoryTable data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <TransferModal modal={modal} toggle={toggle} />
      </Container>
    </Fragment>
  );
};

export default EarnInterest;
