import React from 'react';
import { Image } from '../../../AbstractElements';
import { IMAGES } from '../../../Library/theme';
import { useNavigate } from 'react-router';

const MothriftLogo = () => {
    const navigate = useNavigate();
    return (
        <div style={{ position: 'absolute', margin: '20px 0 0 40px', zIndex: 1, cursor: 'pointer' }} onClick={() => { navigate('/home') }}>
            <Image attrImage={{ className: 'p-0', src: IMAGES.logo_yellow, alt: 'shield', style: { width: 200 } }} />
        </div>
    )
};

export default MothriftLogo;