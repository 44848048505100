import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../AbstractElements';

import Transactions from './Transactions';

import UserContext from '../../../_helper/User';
import { toast } from 'react-toastify';
import { getPaymentHistoryAPI } from '../../../Library/apis';
import WithrawModal from './WithrawModal';


const TransactionHistory = () => {

  const { socket } = useContext(UserContext);
  const [userPaymentHistory, setUserPaymentHistory] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (socket) {
      socket.on('message', (message) => {
        if (message.includes('bank')) {
          getPaymentHistoryAPI().then(res => {
            setUserPaymentHistory(res.body);
          }).catch(() => { })
        }
      });
    }
  }, []);

  const updatePaymentHistory = () => {
    getPaymentHistoryAPI().then(res => {
      setUserPaymentHistory(res.body);
    }).catch(() => { })
  }

  useEffect(() => {
    updatePaymentHistory();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Transaction History' parent='mothrift' title='Withraw Fund' />
      <Container fluid={true}>
        <Row>
          <Col xxl='12' xl='12' className='box-col-4'>
            <Row>
              <Col sm='12'>
                <Transactions data={userPaymentHistory} />
              </Col>
            </Row>
          </Col>
        </Row>
        <WithrawModal modal={modal} toggle={toggle} updatePaymentHistory={updatePaymentHistory} />
      </Container>
    </Fragment>
  );
};

export default TransactionHistory;
