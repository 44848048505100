import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Context from './index';
import { getBankListAPI, getTermFixesAPI, getUserAPI, getUserAvailableTermsAPI, getUserNotificationsAPI, getWalletInfoAPI, updateSocketIdAPI } from '../../Library/apis';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import { SOCKET_URL } from '../../Library/constant';

const UserProvider = (props) => {

  const [userInfo, setUserInfo] = useState({
    userName: '',
    userId: '',
    profileImageUrl: '',
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phoneNumber: '',
    bankVerificationNumber: '',
    bankDetails: {}
  });

  const [userWalletInfo, setUserWalletInfo] = useState({
    savings: 0,
    terms: 0,
    interest: 0
  });

  const [bankList, setBankList] = useState([]);

  const [userNotifications, setUserNoficiations] = useState([]);

  const [userAvailableTermInfo, setUserAvailableTermInfo] = useState([]);

  const [userTermSavingsInfo, setUserTermSavingsInfo] = useState([]);

  const [socket, setSocket] = useState(null);

  const [isTransfer, setIsTransfer] = useState(false);

  useEffect(() => {
    const newSocket = io(SOCKET_URL);
    setSocket(newSocket);

    newSocket.on('newConnected', (data) => {
      updateSocketIdAPI({ socketId: data.socketId })
        .then(() => { })
        .catch(e => { });
    });

    newSocket.on('message', (message) => {
      toast.success(message, { autoClose: 5000 });
      updateUserNotificationsInfo();
      updateUserWalletInfo();
      updateUserAvailableTerms();
      updateUserTermSavingsInfo();
    });
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    getBankListAPI().then(res => {
      setBankList(res.body);
    }).catch(() => { });
  }, []);

  useEffect(() => {
    updateUserNotificationsInfo();
  }, [userNotifications]);

  useEffect(() => {
    updateUserWalletInfo();
  }, [userWalletInfo]);

  useEffect(() => {
    updateUserTermSavingsInfo();
  }, [userAvailableTermInfo]);

  useEffect(() => {
    updateUserAvailableTerms();
  }, [userAvailableTermInfo]);

  useEffect(() => {
    updateUserInfo();
  }, [userInfo]);

  const updateUserNotificationsInfo = () => {
    getUserNotificationsAPI().then(res => {
      if (!_.isEqual(res.body, userNotifications)) {
        setUserNoficiations(res.body);
      }
    }).catch(e => console.log(e));
  }

  const updateUserInfo = () => {
    getUserAPI().then(res => {
      if (!_.isEqual(res.body, userInfo)) {
        setUserInfo(res.body);
      }
    }).catch(e => console.log(e));
  }

  const updateUserWalletInfo = () => {
    getWalletInfoAPI().then(res => {
      const newWalletInfo = {
        savings: res.body?.totalSavingsWallet,
        terms: res.body?.totalTermSavingsWallet,
        interest: res.body?.totalInterestWallet
      };
      if (!_.isEqual(newWalletInfo, userWalletInfo)) {
        setUserWalletInfo(newWalletInfo);
      }
    }).catch(e => { console.log(e) })
  }

  const updateUserAvailableTerms = () => {
    getUserAvailableTermsAPI().then(res => {
      setUserAvailableTermInfo(prev => {
        if (!_.isEqual(res?.body, userAvailableTermInfo)) return res?.body;
        return prev;
      });
    }).catch(() => { })
  }

  const updateUserTermSavingsInfo = () => {
    getTermFixesAPI().then(res => {
      setUserTermSavingsInfo(prev => {
        if (!_.isEqual(res?.body, userTermSavingsInfo)) return res?.body;
        return prev;
      });
    }).catch(() => { })
  }

  return (
    <Context.Provider
      value={{
        ...props,
        userInfo,
        userWalletInfo,
        userAvailableTermInfo,
        userNotifications,
        userTermSavingsInfo,
        socket,
        bankList,
        isTransfer,
        setIsTransfer,
        setUserAvailableTermInfo: setUserAvailableTermInfo,
        setUserInfo: setUserInfo,
        setUserWalletInfo: setUserWalletInfo,
        updateUserWalletInfo: updateUserWalletInfo,
        updateUserInfo: updateUserInfo,
        updateUserAvailableTerms: updateUserAvailableTerms,
        updateUserNotificationsInfo,
        updateUserTermSavingsInfo
      }}>
      {props.children}
    </Context.Provider>
  );
};

export default UserProvider;
