import React from 'react';
import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Loader from '../Layout/Loader';
import { authRoutes } from './AuthRoutes';
import LayoutRoutes from '../Route/LayoutRoutes';
import PrivateRoute from './PrivateRoute';
import { ToastContainer, toast } from 'react-toastify';

toast.configure();

const Routers = () => {
  const login = useState(JSON.parse(localStorage.getItem('login')))[0];
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem('token');

  useEffect(() => {

    setAuthenticated(JSON.parse(localStorage.getItem('authenticated')));
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
  }, []);

  return (
    <BrowserRouter basename={'/'}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={'/'} element={<PrivateRoute />}>
            {login || authenticated || jwt_token ? (
              <>
                <Route exact path={``} element={<Navigate to={`/mothrift/home`} />} />
                <Route exact path={`/`} element={<Navigate to={`/mothrift/home`} />} />
              </>
            ) : ('')}
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>

          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
        </Routes>
      </Suspense>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default Routers;
