import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import Transactions from './Transactions';
import { useContext } from 'react';
import UserContext from '../../../_helper/User';

const Savings = () => {

  const { userTermSavingsInfo, updateUserTermSavingsInfo } = useContext(UserContext);

  useEffect(() => {
    updateUserTermSavingsInfo();
  }, [])

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Termed Savings' />
      <Container fluid={true}>
        <Col xl='12'>
          <Transactions data={userTermSavingsInfo} />
        </Col>
      </Container>
    </Fragment>
  );
};

export default Savings;
