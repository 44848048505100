import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import PaymentTab from './PaymentTab';

const EditPayment = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Account Settings" title="Payment Details" mainTitle="Payment Details" />
            <Container fluid={true} className="credit-card">
                <PaymentTab />
            </Container>
        </Fragment>
    );
};
export default EditPayment;