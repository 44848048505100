import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogIn, User } from 'react-feather';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { LI, UL, P } from '../../../AbstractElements';
import { Account, LogOut } from '../../../Constant';
import UserContext from '../../../_helper/User';

const UserHeader = () => {
  const navigate = useNavigate();

  const Logout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('token');
    localStorage.removeItem('login');
    localStorage.removeItem('authenticated');
    navigate(`/home`);
  };

  useEffect(() => {
    const checkCookie = () => {
      const apiCalled = Cookies.get('apiCalled');
      if (!apiCalled) {
        Logout();
        toast.warning('Your Session is expired. Please login again')
      }
    };
    const intervalId = setInterval(checkCookie, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const UserMenuRedirect = (redirect) => {
    navigate(redirect);
  };

  const { userInfo } = useContext(UserContext);

  return (
    <li className='profile-nav onhover-dropdown pe-0 py-0'>
      <div className='media profile-media'>
        <Avatar name={userInfo.userName} size="40" round src={userInfo.profileImageUrl} textMarginRatio={0.2} />
        <div className='media-body'>
          <span className='font-outfit font16-semibold'>{userInfo.userName}</span>
          <P attrPara={{ className: 'font-outfit mb-0 text-primary', color: 'primary' }}>{`Wallet: ${userInfo?.userId}`}</P>
        </div>
      </div>
      <UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
        <LI attrLI={{ onClick: () => UserMenuRedirect(`/mothrift/account-settings/profile`), }}>
          <User />
          <span>{Account} </span>
        </LI>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
