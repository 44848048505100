import React, { useRef, useState } from 'react';
import { Row, Col, Card } from 'reactstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import { Btn, H1, H3, H4, H5, H6, Image, P } from '../../../AbstractElements';
import TopHeader from './TopHeader';

import landing_bg from '../../../assets/images/landing/landing_section1.jpg';
import landing_card1 from '../../../assets/images/landing/landing_card_1.png';
import landing_card2 from '../../../assets/images/landing/landing_card_2.png';
import landing_card3 from '../../../assets/images/landing/landing_card_3.png';
import landing_section1 from '../../../assets/images/landing/landing_section1.png';
import landing_section4 from '../../../assets/images/landing/landing_section4.png';

import landing_section4_card1 from '../../../assets/images/landing/landing_section4_card1.svg';
import landing_section4_card2 from '../../../assets/images/landing/landing_section4_card2.svg';
import landing_section4_card3 from '../../../assets/images/landing/landing_section4_card3.svg';

import landing_section5 from '../../../assets/images/landing/landing_section5.png';
import landing_section5_apple from '../../../assets/images/landing/landing_section5_apple.png';
import landing_section5_google from '../../../assets/images/landing/landing_section5_google.png';

import landing_section6 from '../../../assets/images/landing/landing_section6.png';

import landing_section1_shield from '../../../assets/images/landing/landing_shield.svg';
import landing_section1_women from '../../../assets/images/landing/landing_section1_women.png';

import './index.css';
import 'swiper/css';
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router';
import Footer from './Footer';

const SectionData = {
    Section1: {
        text1: "Securely save and grow your extra cash",
        text2: "MoThrift is focused on helping you get more from your extra cash through short-term or long-term savings that you are able to put away. Meet your financial goals with ease using MoThrift's easy savings plans.",
        text3: "Backed by highest standards of industry security",
        button: "Get started"
    },
    Section2: {
        text1: "Choose any desired savings duration and easily withdraw your money",
        text2: "Flexible savings terms, your choice. Hassle-free withdrawals. Tailor your savings journey with us, ensuring your money works on your schedule."
    },
    Section3: {
        text1: "Explore Our Products Offerings",
        text2: "Discover the opportunities in putting away your extra cash to work for you. Explore our product offerings designed to empower your cash growth dreams.",
        card: [
            {
                image: landing_card1,
                title: 'Savings',
                description: 'Start saving by simply signing up now.'
            },
            {
                image: landing_card2,
                title: 'Earn Interests',
                description: 'Maximize your savings with competitive interest rates, turning your financial goals into reality.'
            },
            {
                image: landing_card3,
                title: 'Withraw Funds',
                description: 'Experience the flexibility of withdrawing your funds effortlessly and swiftly, with easy and quick withdrawal options.'
            }
        ]
    },
    Section4: {
        text1: "Build Your Savings and Earn Interests in Simple Steps",
        text2: "Embark on your savings journey with MoThrift. Our streamlined process makes saving and earning interest effortless and rewarding."
    },
    Section5: {
        text1: "How we support customers",
        card: [
            {
                image: landing_section4_card1,
                title: 'Bank-level Security',
                description: 'Rest easy knowing that your data and transactions are protected by the same level of security used in financial systems.'
            },
            {
                image: landing_section4_card2,
                title: '24/7 Customer Support',
                description: 'Receive fast and friendly support from our knowledgeable customer support team whenever you need.'
            },
            {
                image: landing_section4_card3,
                title: 'Direct Bank Transfers',
                description: 'Withdraw your funds to the bank account of your choice from your saved money at any time.'
            }
        ]
    },
    Section6: {
        text1: "Our Stories",
        text2: "See what our users have to say",
        users: [
            {
                name: 'Piper Perabo',
                role: 'Entrepreneur',
                description: "Lorem ipsum dolor sit amet, consectetur adipioscing edit, sed to eiusmod tempor incididunt ut aalore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipioscing edit, sed to eiusmod tempor incididunt ut aalore et dolore magna aliqua."
            },
            {
                name: 'Piper Perabo',
                role: 'Entrepreneur',
                description: "Lorem ipsum dolor sit amet, consectetur adipioscing edit, sed to eiusmod tempor incididunt ut aalore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipioscing edit, sed to eiusmod tempor incididunt ut aalore et dolore magna aliqua."
            },
            {
                name: 'Piper Perabo',
                role: 'Entrepreneur',
                description: "Lorem ipsum dolor sit amet, consectetur adipioscing edit, sed to eiusmod tempor incididunt ut aalore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipioscing edit, sed to eiusmod tempor incididunt ut aalore et dolore magna aliqua."
            },
            {
                name: 'Piper Perabo',
                role: 'Entrepreneur',
                description: "Lorem ipsum dolor sit amet, consectetur adipioscing edit, sed to eiusmod tempor incididunt ut aalore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipioscing edit, sed to eiusmod tempor incididunt ut aalore et dolore magna aliqua."
            },
        ]
    },
    SessionFooter: {
        address: {
            title: 'MoThrift',
            data: [
                '5 Bathurst Street',
                'Wuse 2',
                'Abuja, FCT',
            ]
        },
        parts: [
            {
                title: 'Our Company',
                data: [
                    'About Us',
                    'FAQs',
                ]
            },
            {
                title: 'Products',
                data: [
                    'Savings Plan',
                ]
            },
            {
                title: 'Legal',
                data: [
                    'Privacy',
                    'Terms',
                ]
            },
            {
                title: 'Contact US',
                data: [
                    'WhatsApp: 0903 669 1857'
                ]
            },
        ]
    }
}

const LandingPage = () => {

    const [swiper, setSwiper] = useState(null);
    const navigate = useNavigate();
    const handleNavigate = (path) => navigate(path);

    const handleSection6Prev = () => {
        swiper && swiper.slidePrev();
    }

    const handleSection6Next = () => {
        swiper && swiper.slideNext();
    }

    const handleGetStarted = () => {
        navigate('/login');
    }

    return (
        <div className="landing-page">
            <div className='first-section' style={{ backgroundImage: `url(${landing_bg})`, }}>
                <TopHeader />
                <div style={{ zIndex: 1 }}>
                    <div style={{ maxWidth: '1050px', marginTop: '180px', minHeight: '192px' }}>
                        <TypeAnimation
                            sequence={[SectionData.Section1.text1, '', SectionData.Section1.text1, 2000]}
                            className='font96_outfit text-light'
                            cursor={false}
                            speed={200}
                            repeat={Infinity}
                        />
                    </div>
                    <H5 attrH5={{ className: 'font20_outfit text-light', style: { maxWidth: '800px', marginTop: 40, marginBottom: 40 } }}>{SectionData.Section1.text2}</H5>
                    <Fade left>
                        <Btn attrBtn={{ className: 'font36_outfit text-light', color: 'warning', style: { width: '245px', height: '110px', borderRadius: '100px' }, onClick: handleGetStarted }}>Get started</Btn>
                        <Row className='p-t-40 p-l-15'>
                            <Image attrImage={{ className: 'p-0', src: landing_section1_shield, alt: 'shield', style: { width: 41 } }} />
                            <P attrPara={{ className: 'font16_dmsans text-light mb-4 mt-4', style: { maxWidth: '50%', display: 'flex', alignItems: 'center' } }}>{SectionData.Section1.text3}</P>
                        </Row>
                    </Fade>
                </div>
                <div className='women-container'>
                    <Image attrImage={{ className: 'p-0', src: landing_section1_women, alt: 'shield' }} />
                </div>
            </div>

            <Fade right>
                <Row className="second-section">
                    <H1 attrH1={{ className: 'font80_outfit text-dark text-center', style: { maxWidth: '70%', marginTop: '150px' } }}>{SectionData.Section2.text1}</H1>
                    <Col xs={12} className='d-flex align-items-center justify-content-center'>
                        <H1 attrH1={{ className: 'font20_outfit text-dark text-center', style: { maxWidth: '750px', marginTop: '50px', lineHeight: 1.5 } }}>{SectionData.Section2.text2}</H1>
                    </Col>
                    <Col xs={12} className='d-flex align-items-center justify-content-center m-t-50'>
                        <Btn attrBtn={{ className: 'font14_outfit text-light', onClick: handleGetStarted, color: 'warning', style: { width: '186px', height: '64px', borderRadius: '100px' } }}>Get Started</Btn>
                    </Col>

                </Row>
            </Fade>

            <Fade bottom>
                <Row className="third-section">
                    <H1 attrH1={{ className: 'font80_outfit text-dark', style: { maxWidth: '800px', marginTop: '90px' } }}>{SectionData.Section3.text1}</H1>
                    <Col xs={12}>
                        <H5 attrH5={{ className: 'font20_outfit text-dark m-4', style: { maxWidth: '700px' } }}>{SectionData.Section3.text2}</H5>
                    </Col>
                </Row>
            </Fade>

            <div id="savings">
                <Row className="second-section">
                    {SectionData.Section3.card.map(item =>
                        <Col xs={10}>
                            <Card body>
                                <Row className='m-4'>
                                    <Col xxl={3} xl={3} xs={12} className='d-flex justify-content-center align-items-center'>
                                        <Image attrImage={{ src: item.image, alt: item.title, style: { height: '200px' } }} />
                                    </Col>
                                    <Col xxl={9} xl={9} xs={12}>
                                        <Row className="card-section">
                                            <H1 attrH1={{ className: 'font64_outfit text-dark' }}>{item.title}</H1>
                                            <Col xs={12}>
                                                <H5 attrH5={{ className: 'font20_outfit text-dark' }}>{item.description}</H5>
                                            </Col>
                                            <Col xs={12}>
                                                <Btn attrBtn={{ className: 'font14_outfit text-light', onClick: handleGetStarted, color: 'warning', style: { width: '186px', height: '64px', borderRadius: '100px' } }}>Get Started</Btn>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    )}
                </Row>
            </div>

            <Fade right>
                <Row className='fourth-section'>
                    <Col xxl={4} lg={12} className='d-flex justify-content-center align-items-center'>
                        <div className='section4_card'>
                            <Image attrImage={{ src: landing_section4, alt: 'section4', style: { height: '400px', width: 'auto' } }} />
                        </div>
                    </Col>
                    <Col xxl={8} lg={12}>
                        <Row className="card-section">
                            <H1 attrH1={{ className: 'font60_outfit text-dark', style: { maxWidth: '800px', marginTop: '90px' } }}>{SectionData.Section4.text1}</H1>
                            <Col xs={12}>
                                <H5 attrH5={{ className: 'font20_outfit text-dark' }}>{SectionData.Section4.text2}</H5>
                            </Col>
                            <Col xs={12}>
                                <Btn attrBtn={{ className: 'font14_outfit text-light', onClick: handleGetStarted, color: 'warning', style: { width: '186px', height: '64px', borderRadius: '100px' } }}>Get Started</Btn>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fade>


            <Row className="fifth-section">
                <H1 attrH1={{ className: 'font64_outfit text-light', style: { textAlign: 'center', margin: '20px 0 40px 0' } }}>{SectionData.Section5.text1}</H1>
                <Col xxl={6} lg={6}>
                    <Zoom>
                        <div className='d-flex justify-content-center align-items-center m-t-50'>
                            <Image attrImage={{ src: landing_section5, alt: 'section4', style: { height: 'auto', width: '35%' } }} />
                        </div>
                    </Zoom>
                </Col>
                <Col xxl={4} lg={6} xl={5} className='m-t-50'>
                    {SectionData.Section5.card.map(item =>
                        <Fade right cascade big>
                            <Card body>
                                <Image attrImage={{ src: item.image, alt: 'section4', style: { height: '40px', width: '40px' } }} />
                                <Row className="card-section" style={{ gap: 0, marginTop: 5 }}>
                                    <H5 attrH5={{ className: 'font20_outfit text-dark', style: { fontWeight: 700 } }}>{item.title}</H5>
                                    <Col xs={12} className='mt-0'>
                                        <P attrPara={{ className: 'font16_dmsans text-dark m-t-5', style: { fontWeight: 500, lineHeight: '20px' } }}>{item.description}</P>
                                    </Col>
                                </Row>
                            </Card>
                        </Fade>
                    )}
                </Col>
                <Col xs={12} className='store-container'>
                    <Image attrImage={{ src: landing_section5_apple, alt: 'section4', }} />
                    <Image attrImage={{
                        src: landing_section5_google, alt: 'section4', style: { cursor: 'pointer' }, onClick: () => {
                            window.open('https://play.google.com/store/apps/details?id=com.mothrift', '_blank');
                        }
                    }} />
                </Col>
            </Row >

            {/* <div className="sixth-section" style={{ position: 'relative' }}>

                <div className='d-flex' style={{ flexDirection: 'column' }}>
                    <div className='font64_outfit text-dark text-center'>{SectionData.Section6.text1}</div>
                    <div className='font20_outfit text-dark text-center' style={{ fontWeight: 400 }}>{SectionData.Section6.text2}</div>
                </div>

                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    onSwiper={(swiper) => setSwiper(swiper)}
                >
                    {SectionData.Section6.users.map((item, i) =>
                        <SwiperSlide key={`${item.name}_${i}`}>
                            <Row>
                                <Col xs={4} className='d-flex justify-content-center align-items-center'>
                                    <Image attrImage={{ src: landing_section6, alt: 'section6', style: { height: 'auto', width: '300px' } }} />
                                </Col>
                                <Col xs={8} className="d-flex justify-content-center align-items-center">
                                    <Row className="card-section" >
                                        <span className='font40_outfit text-dark'>{item.name}</span>
                                        <span className='font16_outfit text-dark'>{item.role}</span>
                                        <Col xs={12}>
                                            <H5 attrH5={{ className: 'font20_outfit text-dark' }}>{item.description}</H5>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </SwiperSlide>
                    )}
                </Swiper>

                <div className='font20_outfit d-flex' style={{ position: 'absolute', bottom: 40, right: 50 }}>
                    <div className='me-1 d-flex' onClick={handleSection6Prev} style={{ justifyContent: 'center', alignItems: 'center', background: '#5551', borderRadius: 100, width: 40, height: 40, cursor: 'pointer' }}>
                        <i className="fa fa-regular fa-chevron-left" style={{ color: '#666' }}></i>
                    </div>
                    <div onClick={handleSection6Next} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#5551', borderRadius: 100, width: 40, height: 40, cursor: 'pointer' }}>
                        <i className="fa fa-regular fa-chevron-right" style={{ color: '#666' }}></i>
                    </div>
                </div>

            </div > */}

            {/* Footer */}
            <Footer />

        </div >
    );
};

export default LandingPage;
