import React, { useContext } from "react";
import { Gift, Trash2 } from "react-feather";
import { Card, CardBody } from "reactstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { H3, P, H4 } from "../../../AbstractElements";
import {
  convertDate,
  formatCurrency,
  formatCurrency2,
  isProd,
} from "../../../Library/custom_utils";
import TermCardChart from "./TermCardChart";
import { cancelFixAPI } from "../../../Library/apis";
import UserContext from "../../../_helper/User";
import { useNavigate } from "react-router";

const TermCard = ({ data }) => {
  const userContext = useContext(UserContext);

  const fixData = {
    term1: { chart: { color: "#ff000088" } },
    term2: { chart: { color: "var(--theme-deafult)" } },
    term30: { chart: { color: "var(--theme-deafult)" } },
    term90: { chart: { color: "var(--theme-secondary)" } },
    term180: { chart: { color: "var(--theme-secondary)" } },
    term365: { chart: { color: "#445566" } },
  };

  const handleDeleteTerm = () => {
    Swal.fire({
      title: "Do you want to cancel this Term Savings?",
      text: "If yes, you will not earn any interest for uncompleted Term Savings",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        cancelFixAPI({ fixId: data._id })
          .then((res) => {
            toast.success("Canceled term.", { autoClose: 5000 });
            userContext.updateUserWalletInfo();
            userContext.updateUserAvailableTerms();
            userContext.updateUserTermSavingsInfo();
          })
          .catch((e) => {
            console.log(e);
            toast.error("An error occurred while cancelling the term.", {
              autoClose: 5000,
            });
          });
      }
    });
  };

  const navigate = useNavigate();
  const handleGoBuyAirtime = (termSavingsId) => {
    navigate(`/mothrift/buy-airtime/${termSavingsId}`);
  };

  const getDayRemaining = () => {
    const startedDate = new Date(data.createdAt);
    const now = new Date();
    const timeDiff = (now - startedDate) / (1000 * 3600 * 24);
    return Math.max(Math.floor(timeDiff), 0);
  };

  const getHoursRemaning = () => {
    const startedDate = new Date(data.createdAt);
    const now = new Date();
    const timeDiff = (now - startedDate) / (1000 * 60);
    return Math.max(120 - Math.floor(timeDiff), 0);
  };

  const getEndDate = (start, add) => {
    const startedDate = new Date(start);
    startedDate.setDate(startedDate.getDate() + parseInt(add));
    return convertDate(startedDate);
  };

  const unit = data.term == 1 ? "hours" : "days";
  const series =
    data.term == 1
      ? [((120 - getHoursRemaning()) * 100) / 120]
      : [(getDayRemaining() * 100) / data.term];

  return (
    <Card
      className="widget-hover pt-0 pb-0 font-outfit"
      style={{ borderRadius: "15px" }}
    >
      <CardBody className="radial-progress-card">
        <div>
          <div className="d-flex">
            <div style={{ flex: 2 }}>
              <H4 attrH4={{ className: "font20-bold f-light mb-3" }}>{`Term (${
                data.term == 1 ? 2 : data.term
              } ${unit}) `}</H4>
              <H3 attrH3={{ className: `font-secondary font46-bold p-t-15` }}>
                {formatCurrency2(data.amount, 12)}
              </H3>
            </div>
            <div className="radial-chart-wrap" style={{ flex: 1 }}>
              <TermCardChart
                chartHeight={200}
                series={series}
                chartOption={fixData[`term${data.term}`]}
                daysLeft={Math.max(data.term - getDayRemaining(), 0)}
                hoursLeft={data.term == 1 ? getHoursRemaning() : null}
              />
            </div>
          </div>

          <div className={"d-flex"} style={{ gap: 10 }}>
            <div
              style={{
                borderRadius: 100,
                background: "#FFE3DE",
                padding: "5px 15px",
              }}
            >
              <P attrPara={{ className: "font-danger font14-semibold" }}>
                Date Started: {convertDate(data.createdAt)}
              </P>
            </div>
            <div
              style={{
                borderRadius: 100,
                background: "#FFE3DE",
                padding: "5px 15px",
              }}
            >
              <P attrPara={{ className: "font-danger font14-semibold" }}>
                End Date:{" "}
                {getEndDate(data.createdAt, data.term == 1 ? 0 : data.term)}
              </P>
            </div>
          </div>
        </div>

        {/* {!isProd() && <div className='scale-button' style={{ position: 'absolute', right: '10px', bottom: '10px' }} onClick={handleDeleteTerm}>
          <Trash2 className='me-2 badge-light-danger' />
        </div>} */}

        {data.remainingAirtimes > 0 && (
          <div
            className="scale-button"
            style={{ position: "absolute", right: "20px", top: "20px" }}
            onClick={() => {
              handleGoBuyAirtime(data._id);
            }}
          >
            <Gift className="me-2 badge-light-danger" />
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ position: "absolute", right: -3, top: -10 }}
            >
              <span className="badge rounded-pill badge-secondary">
                {data.remainingAirtimes}
              </span>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default TermCard;
